import {
  IRuntimeSyndicationOperation,
  MigrationType,
  SyncCoreDataDefinitionsEnumTranslator,
  SyndicationPriority,
  SyndicationStatus,
  SyndicationType,
} from '@edgebox/sync-core-data-definitions';
import { ClientSyndicationEntity, ISyndicationRequestFilters } from '@edgebox/sync-core-rest-client';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faGaugeMax } from '@fortawesome/pro-light-svg-icons/faGaugeMax';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { dangerStatus, doneStatus, SyndicationStatusIcon, warningStatus } from './Customer/SyndicationStatusIcon';
import { EntityTypeVersionName } from './EntityTypeVersionName';
import { FlowName } from './FlowName';
import { OperationErrorList } from './OperationErrorList';
import { OperationName } from './OperationName';
import { PagedList } from './PagedList';
import { SyndicationName } from './SyndicationName';
import {
  DURATION_DANGER,
  DURATION_WARNING,
  FULL_DURATION_DANGER,
  FULL_DURATION_WARNING,
  ICON_EXPORT_CONFIG,
  ICON_PULL,
  ICON_PULL_MANY,
  ICON_PUSH,
  ICON_PUSH_MANY,
  formatDuration,
} from '../Helpers';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';
import { getDateName } from './FormatDate';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { PoolName } from './PoolName';
import { INVALID_FULL_DURATION } from './Customer/Syndication/SyndicationHelper';
import { ERROR_INDEX_TRACE } from './Customer/Syndication/OperationErrorDetails';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';

interface IProps extends ISyndicationRequestFilters {
  backend?: boolean;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  includeViewLink?: boolean;
  groupBySite?: boolean;
  groupByDate?: boolean;
  showSiteName?: boolean;
  watch?: boolean;
  onSelect?: (entity: ClientSyndicationEntity) => void;
  onErrorDetails?: (syndication: ClientSyndicationEntity, errorDetails: { operationIndex: number; errorIndex: number }) => void;
  isOnPageBackground?: boolean;
  type?: 'config' | 'content';
}

interface IState extends ISyncCoreApiComponentState, ISyndicationRequestFilters {}

const REFRESH_INTERVAL_RUNNING = 5_000;

interface IOperationProps {
  operation: IRuntimeSyndicationOperation;
  onErrorDetails?: (errorIndex: number) => void;
}
interface IOperationState {}
export class OperationListItem extends React.Component<IOperationProps, IOperationState> {
  render() {
    const { operation, onErrorDetails } = this.props;

    let attempts = operation.errors?.length || 0;
    if (operation.status === SyndicationStatus.Finished) {
      attempts++;
    }

    const muted = operation.cloneExisting;
    const mutedClassName = muted ? 'opacity-50' : '';

    const title =
      (operation?.updateCount ?? null) !== null || (operation?.ignoreCount ?? null) !== null
        ? `applied ${operation.updateCount ?? 0} / ${(operation.updateCount ?? 0) + (operation.ignoreCount ?? 0)} updates.`
        : undefined;

    return (
      <>
        <Row style={{ zIndex: 1 }}>
          <Col xs={4} lg={3}>
            <SyndicationStatusIcon
              status={operation.status}
              errorType={operation.errors?.[0]?.type}
              foregroundClassName={mutedClassName}
              title={title}
            />{' '}
            <Badge
              className={mutedClassName}
              bg={
                attempts
                  ? dangerStatus.includes(operation.status) ||
                    warningStatus.includes(operation.status) ||
                    attempts > 1 ||
                    (operation.status === SyndicationStatus.Aborted && operation.errors?.length)
                    ? 'warning'
                    : 'success'
                  : 'light'
              }
            >
              {attempts === 1 ? '1 attempt' : `${attempts} attempts`}
            </Badge>
          </Col>
          <Col xs={8} lg={9}>
            <div className={mutedClassName}>
              <OperationName operation={operation} />
              {operation.traceRequestDetails?.getId() && onErrorDetails ? (
                <Button variant="link" onClick={() => onErrorDetails(ERROR_INDEX_TRACE)}>
                  Trace
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
        {operation.errors?.length ? (
          <OperationErrorList
            errors={operation.errors}
            onDetails={onErrorDetails ? (index) => onErrorDetails(index) : undefined}
            succeeded={operation.status === SyndicationStatus.Finished ? true : undefined}
          />
        ) : undefined}
      </>
    );
  }
}

export function getLatestError(syndication: ClientSyndicationEntity) {
  return syndication.operations
    ?.map((operation, operationIndex) => ({ operation, operationIndex }))
    .filter(({ operation }) =>
      [SyndicationStatus.Retrying, SyndicationStatus.Failed, SyndicationStatus.LimitExceeded, SyndicationStatus.Aborted].includes(
        operation.status
      )
    )
    .map(({ operation, operationIndex }) =>
      (operation.errors || []).map((error, errorIndex) => ({ error, errorIndex, operation, operationIndex }))
    )
    .flat()
    .sort(({ error: a }, { error: b }) => b.timestamp - a.timestamp)[0];
}

interface IItemProps {
  entity: ClientSyndicationEntity;
  timeOnly?: boolean;
  showSiteName?: boolean;
  highlight?: boolean;
  onClick?: () => void;
  onErrorDetails?: (errorDetails: { operationIndex: number; errorIndex: number }) => void;
}
interface IItemState extends ISyncCoreApiComponentState {
  entity?: ClientSyndicationEntity;
  showOperations?: boolean;
  confirm?: 'retry' | 'retry-loading' | 'abort' | 'abort-loading';
}
export class SyndicationListItem extends SyncCoreApiComponent<IItemProps, IItemState> {
  refreshTimeout: any;

  async load() {
    let entity = this.state.entity || this.props.entity;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }

    // Called a second time, so we want to refresh now.
    if (this.state.entity) {
      entity = await this.api.syndication.syndications.item(entity.id, true, { includeUsage: true });
    }

    if (!doneStatus.includes(entity.status)) {
      this.refreshTimeout = setTimeout(() => this.__isMounted && this.load(), REFRESH_INTERVAL_RUNNING);
    }

    return {
      entity,
    };
  }

  render() {
    const { timeOnly, showSiteName, onClick, onErrorDetails, highlight } = this.props;
    const entity = this.state.entity || this.props.entity;

    const finishedOperations = entity.operations?.filter((c) => doneStatus.includes(c.status)).length || 0;

    const languages = entity.changedLanguages || entity.usage?.translations.map((c) => c.language);

    const latestError = getLatestError(entity);

    return (
      <>
        <div
          className={`d-flex flex-rows pt-2 ${/*hasOperations ? 'cursor-pointer' :*/ ''} paged-syndication-list-item ${
            highlight ? 'border border-primary' : ''
          } ${onClick ? 'mt-2 bg-white-selectable hover-shadow cursor-pointer rounded pb-1' : ''}`}
          onClick={
            onClick
              ? (e) => {
                  if ((e.target as HTMLElement).tagName === 'A' || (e.target as HTMLElement).tagName === 'BUTTON') {
                    return;
                  }

                  onClick();
                }
              : undefined
          }
        >
          <div className="flex-grow-0 flex-shrink-0" style={{ flexBasis: '250px' }}>
            <SyndicationStatusIcon
              type={entity.type}
              status={entity.status}
              errorType={entity.operations?.[0]?.errors?.[0]?.type}
              title={entity.operations?.length ? `finished ${finishedOperations} / ${entity.operations.length} operations` : undefined}
            />
            <OverlayTrigger
              placement="right"
              overlay={(props) => (
                <Tooltip id={`tooltip-type-${entity.id}`} {...props} className="tooltip-wide">
                  {entity.flow || entity.pools?.length ? (
                    <ul>
                      {entity.flow ? (
                        <li>
                          Flow: <FlowName id={entity.flow.getId()!} inline />
                        </li>
                      ) : null}
                      {entity.pools?.length ? (
                        <li>
                          Pools:
                          <ul>
                            {entity.pools.map((c) => (
                              <li key={c.getId()}>
                                <PoolName id={c.getId()} inline />
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : undefined}
                    </ul>
                  ) : (
                    <span>{SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SyndicationType', entity.type)}</span>
                  )}
                </Tooltip>
              )}
            >
              <span className="me-3">
                <FontAwesomeIcon
                  icon={
                    entity.type === SyndicationType.RetrieveConfig
                      ? ICON_EXPORT_CONFIG
                      : [SyndicationType.PushEntity, SyndicationType.DeleteEntity, SyndicationType.DeleteEmbeddedEntity].includes(
                            entity.type
                          )
                        ? entity.migration?.getId()
                          ? ICON_PULL_MANY
                          : ICON_PULL
                        : entity.migration?.getId() && entity.migrationType !== MigrationType.PushManually
                          ? ICON_PUSH_MANY
                          : ICON_PUSH
                  }
                />
              </span>
            </OverlayTrigger>
            {languages && (
              <OverlayTrigger
                placement="right"
                overlay={(props) => (
                  <Tooltip id={`tooltip-languages-${entity.id}`} {...props} className="tooltip-wide">
                    {!languages.length ? (
                      <em>none</em>
                    ) : languages.length < 2 ? (
                      languages[0]
                    ) : (
                      <ul>
                        {languages.map((c) => (
                          <li key={c}>{c}</li>
                        ))}
                      </ul>
                    )}
                  </Tooltip>
                )}
              >
                <span className="me-3">
                  <FontAwesomeIcon icon={faLanguage} /> {languages.length}
                </span>
              </OverlayTrigger>
            )}
            <span className="fa-stack pt-1 text-primary">
              {entity.skipUnchanged && (
                <span className="me-3" title="Unchanged entities are not updated to improve the performance of this update.">
                  <FontAwesomeIcon icon={faGaugeMax} />
                </span>
              )}
            </span>
            <span className="fa-stack pt-1 text-primary">
              {(!!entity.operations?.find((c) => !!c.traceRequestDetails?.getId()) || (entity.isActive && entity.trace)) && (
                <span className="me-3" title="Traced">
                  <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                </span>
              )}
            </span>
            <span className="fa-stack pt-1 text-danger">
              {entity.priority && entity.priority > SyndicationPriority.Normal ? (
                <span
                  className="me-3"
                  title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SyndicationPriority', entity.priority as any)}
                >
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
              ) : null}
            </span>
          </div>
          <div
            className="flex-grow-0 flex-shrink-0 border-start-2 border-light-lighter mb-1 ps-2 pe-2"
            style={{ flexBasis: timeOnly ? '90px' : '150px' }}
          >
            <OverlayTrigger
              placement="right"
              overlay={(props) => (
                <Tooltip id={`tooltip-time-and-duration-${entity.id}`} {...props} className="tooltip-wide">
                  <ul>
                    <li>Started: {entity.createdAt.format('LLL')}</li>
                    {typeof entity.duration === 'number' && <li>Duration: {formatDuration(entity.duration)}</li>}
                    {typeof entity.fullDuration === 'number' && entity.isAutoUpdate && entity.fullDuration < INVALID_FULL_DURATION && (
                      <li>Start to finish: {formatDuration(entity.fullDuration)}</li>
                    )}
                    {entity.rdCount === 1
                      ? typeof entity.rdTotal === 'number' && <li>Request time: {formatDuration(entity.rdTotal)}</li>
                      : !!entity.rdCount && (
                          <li>
                            Requests:
                            <ul>
                              {typeof entity.rdCount === 'number' && <li>Count: {entity.rdCount}</li>}
                              {typeof entity.rdTotal === 'number' && <li>Total time: {formatDuration(entity.rdTotal)}</li>}
                              {typeof entity.rdAverage === 'number' && <li>Average time: {formatDuration(entity.rdAverage)}</li>}
                              {typeof entity.rdShortest === 'number' && <li>Shortest time: {formatDuration(entity.rdShortest)}</li>}
                              {typeof entity.rdLongest === 'number' && <li>Longest time: {formatDuration(entity.rdLongest)}</li>}
                            </ul>
                          </li>
                        )}
                  </ul>
                </Tooltip>
              )}
            >
              <span>{timeOnly ? entity.createdAt.format('LT') : entity.createdAt.fromNow()}</span>
            </OverlayTrigger>
          </div>
          <div className="flex-grow-1 flex-shrink-1 border-start-2 border-light-lighter mb-1 ps-2">
            <SyndicationName entity={entity} showType showSiteName={showSiteName} />
            {entity.rootEntityReference?.name ? (
              <span className="ms-2">
                {' '}
                {entity.rootEntityTypeVersion?.getId() && (
                  <EntityTypeVersionName entityId={entity.rootEntityTypeVersion.getId()} asIcon />
                )}{' '}
                {entity.usage?.viewUrl ? (
                  <ExternalLinkWithIcon to={entity.usage.viewUrl}>{entity.rootEntityReference.name}</ExternalLinkWithIcon>
                ) : entity.rootEntityDetails?.viewUrl ? (
                  <ExternalLinkWithIcon to={entity.rootEntityDetails.viewUrl}>{entity.rootEntityReference.name}</ExternalLinkWithIcon>
                ) : (
                  entity.rootEntityReference.name
                )}
              </span>
            ) : undefined}
          </div>
          <div className="flex-grow-0 flex-shrink-0">
            {latestError && onErrorDetails ? (
              <Button variant="link" className="text-danger" onClick={() => onErrorDetails(latestError)}>
                Latest Error
              </Button>
            ) : null}
          </div>
          <div className="flex-grow-0 flex-shrink-0 text-end" style={{ flexBasis: '170px' }}>
            {typeof entity.duration === 'number' && (
              <>
                <span className="text-muted"> in </span>
                <span
                  className={
                    entity.duration > DURATION_DANGER ? 'text-danger' : entity.duration > DURATION_WARNING ? 'text-warning' : 'text-muted'
                  }
                >
                  {formatDuration(entity.duration)}
                </span>
                {typeof entity.fullDuration === 'number' && entity.isAutoUpdate && entity.fullDuration < INVALID_FULL_DURATION && (
                  <>
                    <span className="text-muted"> / </span>
                    <span
                      className={
                        entity.fullDuration > FULL_DURATION_DANGER
                          ? 'text-danger'
                          : entity.fullDuration > FULL_DURATION_WARNING
                            ? 'text-warning'
                            : 'text-muted'
                      }
                    >
                      {formatDuration(entity.fullDuration)}
                    </span>
                    <span className="text-muted"> total</span>
                  </>
                )}
              </>
            )}
          </div>
          {onClick && (
            <div className="flex-grow-0 flex-shrink-0 pe-2 ps-2 text-dark">
              <FontAwesomeIcon icon={faChevronRight} className="align-middle" />
            </div>
          )}
        </div>
      </>
    );
  }
}

export class PagedSyndicationList extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const {
      emptyMessage,
      emptyMessageWithNoFilters,
      groupBySite,
      groupByDate,
      showSiteName,
      onSelect,
      onErrorDetails,
      isOnPageBackground,
      watch,
    } = this.props;

    return (
      <PagedList<ClientSyndicationEntity, ISyndicationRequestFilters>
        watch={watch}
        getItemId={(item) => item.id}
        key={JSON.stringify(this.props)}
        groupBy={groupByDate ? (item) => getDateName(item.createdAt) : undefined}
        noPagerIfNotNeeded
        isOnPageBackground={isOnPageBackground}
        request={(page, filter) => {
          return this.api.syndication.syndications.list(
            {
              ...filter,
              ...this.props,
              isRegularSyndication: !filter?.migrationId && !this.props.migrationId && this.props.type !== 'config' ? true : undefined,
              types: this.props.type === 'config' ? [SyndicationType.RetrieveConfig] : undefined,
            },
            { page },
            { includeUsage: this.props.includeViewLink },
            {
              groupBySite,
            }
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No operations match your filter.</Alert>}
        renderItem={(entity, index, meta, highlight) => (
          <SyndicationListItem
            highlight={highlight}
            entity={entity}
            key={entity.id}
            timeOnly={groupByDate}
            showSiteName={showSiteName}
            onClick={onSelect ? () => onSelect(entity) : undefined}
            onErrorDetails={onErrorDetails ? (errorDetails) => onErrorDetails(entity, errorDetails) : undefined}
          />
        )}
      />
    );
  }
}

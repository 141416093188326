import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faRectangle } from '@fortawesome/pro-light-svg-icons/faRectangle';

type ViewModeToggleProps = {
  previewMode: 'list' | 'preview';
  setPreviewMode: (newMode: 'preview' | 'list') => void;
};

const ViewModeToggle = ({ setPreviewMode, previewMode }: ViewModeToggleProps) => {
  const compactView = useMemo(() => {
    if (previewMode === 'list') {
      return true;
    }

    return false;
  }, [previewMode]);

  return (
    <div className="ps-1 d-flex align-items-center embed-pd__view-toggle-container ms-3 px-2">
      {/* Container for view icons */}
      <Button
        variant={'light'}
        onClick={() => setPreviewMode('list')}
        id="compact-view"
        className="bg-none me-2 p-0"
        title="Compact display" // Add title for hover
      >
        <FontAwesomeIcon
          icon={faList}
          className={compactView ? 'embed-pd__view-toggle-icon--active' : 'embed-pd__view-toggle-icon'} // Change color based on active state
        />
      </Button>
      <Button
        variant={'light'}
        onClick={() => setPreviewMode('preview')} // Toggle preview view
        id="preview-view"
        className="bg-none ms-2 p-0"
        title="Display previews" // Add title for hover
      >
        <FontAwesomeIcon
          icon={faRectangle}
          className={!compactView ? 'embed-pd__view-toggle-icon--active' : 'embed-pd__view-toggle-icon'} // Change color based on active state
        />
      </Button>
    </div>
  );
};

export default ViewModeToggle;

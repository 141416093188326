// General feature availability
export const FEATURE_PREVIEWS = 'previews';
export const FEATURE_THROTTLING = 'throttling';
export const FEATURE_DOMAINS = 'domains';

// Feature flag management available at all
export const FEATURE_SELF_SERVICE_FEATURE_FLAGS = 'feature:self-service';

// Allow changing pool assignments
export const FEATURE_DYNAMIC_POOL_ASSIGNMENT = 'syndication:dynamic-pool-assignment';
export const FEATURE_DYNAMIC_POOL_ASSIGNMENT_AVAILABLE = 'syndication:dynamic-pool-assignment:available';

// Custom request timeouts
export const FEATURE_CUSTOM_REQUEST_TIMEOUT = 'syndication:request-timeout';
export const FEATURE_CUSTOM_REQUEST_TIMEOUT_AVAILABLE = 'syndication:request-timeout:available';

// Optimized translation handling
export const FEATURE_REQUEST_PER_TRANSLATION = 'syndication:request-per-translation';
export const FEATURE_REQUEST_PER_TRANSLATION_AVAILABLE = 'syndication:request-per-translation:available';
export const FEATURE_SKIP_UNCHANGED_TRANSLATIONS = 'syndication:skip-unchanged-translations';
export const FEATURE_SKIP_UNCHANGED_TRANSLATIONS_AVAILABLE = 'syndication:skip-unchanged-translations:available';

// Basic auth
export const FEATURE_AUTHENTICATION_ADDITIONAL_BASIC_AUTH = 'authentication:additional-basic-auth:available';

// Local environments / requests polling
export const FEATURE_REQUEST_POLLING = 'site:request-polling';
export const FEATURE_REQUEST_POLLING_AVAILABLE = 'site:request-polling:available';
export const FEATURE_REQUEST_POLLING_TIMEOUT = 'site:request-polling:request-lifetime';

// Optimization: Unchanged entities
export const FEATURE_SKIP_UNCHANGED_ENTITIES_AVAILABLE = 'syndication:skip-unchanged:available';
export const FEATURE_SKIP_UNCHANGED_ENTITIES_MODE = 'syndication:skip-unchanged:mode';
export const FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF: 0 = 0;
export const FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON: 1 = 1;
export const FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE: 2 = 2;
export const FEATURE_SKIP_UNCHANGED_ENTITIES_SLOW_REQUEST_DURATION = 'syndication:skip-unchanged:slow-request-duration';
export const FEATURE_SKIP_UNCHANGED_ENTITIES_LARGE_ENTITY_COUNT = 'syndication:skip-unchanged:large-entity-count';

// 2XX status codes
export const FEATURE_PREFER_2XX_STATUS_CODE = 'syndication:prefer-2xx-status-code';
export const FEATURE_PREFER_2XX_STATUS_CODE_AVAILABLE = 'syndication:prefer-2xx-status-code:available';

// Export configuration asynchronously
export const FEATURE_ASYNC_SITE_CONFIG_AVAILABLE = 'site:async-site-config:available';

// Allow enabling tracing for individual updates
export const FEATURE_SYNDICATION_TRACE_AVAILABLE = 'syndication:trace:available';

// New controller interfaces
export const FEATURE_TYPE_VERSION_BY_MACHINE_NAME_AVAILABLE = 'type:entity-type-version-by-machine-name:available';

// Webhooks
export const FEATURE_WEBHOOKS_AVAILABLE = 'webhooks:available';

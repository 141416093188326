import { IconName, IconProp, findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core';
import { faTrafficLight } from '@fortawesome/pro-light-svg-icons/faTrafficLight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  icon: IconProp;
  rotateIcon?: number;
  dark?: boolean;
  color?: 'dark' | 'light' | 'success' | 'danger' | 'warning';
  iconSpin?: boolean;
}

export function makeNugget(element: Element, iconName: IconName, onClick?: () => void) {
  const text = element.textContent;

  let textElement: Element;
  if (onClick) {
    textElement = document.createElement('span');
    textElement.setAttribute('class', 'ms-1 me-2 p-0 pt-1 text-dark text-decoration-underline cursor-pointer');
    textElement.textContent = text;
    textElement.addEventListener('click', onClick);
  } else {
    textElement = document.createElement('span');
    textElement.setAttribute('class', 'ms-1 me-2 p-0 pt-1 text-dark');
    textElement.textContent = text;
  }

  const iconDefinition = findIconDefinition({ prefix: 'fal', iconName });
  const iconInstance = icon(iconDefinition);

  const iconContainer = document.createElement('span');
  iconContainer.setAttribute('class', 'bg-light text-dark text-center d-inline-block h-100 align-top');
  iconContainer.setAttribute('style', 'border-radius: 50px; aspect-ratio: 1 / 1; opacity: 80%;');
  /*const iconElement = document.createElement('span');
  iconElement.setAttribute("class", `fa fa-${icon}`);
  iconElement.setAttribute("style", "zoom: 0.9;");
  iconContainer.appendChild(iconElement);*/
  if (iconInstance) {
    Array.from(iconInstance.node).map((n) => {
      n.classList.add('align-middle');
      iconContainer.appendChild(n);
    });
  }

  element.innerHTML = '';
  element.setAttribute('class', 'bg-lighter d-inline-block me-2');
  element.setAttribute('style', 'border-radius: 50px; height: 1.7em;');
  element.appendChild(iconContainer);
  element.appendChild(textElement);

  //setTimeout(() => dom.i2svg({node: iconContainer}));
}

function getContrastColor(color: 'dark' | 'light' | 'success' | 'danger' | 'warning') {
  if (color === 'light') {
    return 'black';
  }
  return 'white';
}

export function Nugget({ dark, icon, children, color, rotateIcon, iconSpin }: IProps) {
  return (
    <span
      className={`${dark ? 'bg-white shadow-border-sm' : 'bg-lighter'} d-inline-block me-2 mb-2 ${color ? `border border-${color}` : ''}`}
      style={{ borderRadius: '50px', height: '1.7em', opacity: dark ? '.8' : undefined }}
    >
      <span
        className={`nugget-svg-wrapper ${
          color ? `bg-${color} text-${getContrastColor(color)}` : 'bg-light text-dark'
        } text-center d-inline-block h-100 align-top`}
        style={{ borderRadius: '50px', aspectRatio: '1 / 1', opacity: '80%' }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            zoom: 0.9,
            ...(icon === faTrafficLight ? { marginTop: '7px', marginLeft: '0px' } : {}),
          }}
          className={`${rotateIcon ? `fa-rotate-${rotateIcon}` : ''}`}
          spin={iconSpin}
        />
      </span>
      <span className={`d-inline-flex justify-content-center align-items-center ms-1 me-2 p-0 pt-1 text-${dark ? 'dark' : 'dark'}`}>
        {children}
      </span>
    </span>
  );
}

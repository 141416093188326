import { LeftRightH1, Right } from '@edgebox/react-components';
import {
  SyncCoreDataDefinitionsEnumTranslator,
  SyndicationPriority,
  SyndicationStatus,
  SyndicationType,
} from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntity, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { EmbeddedModal } from '../../EmbeddedModal';
import { WithEntityTypeVersion } from '../../EntityTypeVersionName';
import { OperationListItem, getLatestError } from '../../PagedSyndicationList';
import { doneStatus, SyndicationStatusIcon } from '../SyndicationStatusIcon';
import { DURATION_DANGER, DURATION_WARNING, FULL_DURATION_DANGER, FULL_DURATION_WARNING, formatDuration } from '../../../Helpers';
import { SyncCoreApiContext } from '../../../contexts/SyncCoreApiContext';
import { Nugget } from '../../Nugget';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { getEntityTypeIcon } from '../EntityTypeIcon';
import { getDateName } from '../../FormatDate';
import { SyndicationName, getSyndicationTypeName } from '../../SyndicationName';
import { ExternalLinkWithIcon } from '../../ExternalLinkWithIcon';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { FlowName } from '../../FlowName';
import { PoolName } from '../../PoolName';
import { faGaugeMax } from '@fortawesome/pro-light-svg-icons/faGaugeMax';
import { faGauge } from '@fortawesome/pro-light-svg-icons/faGauge';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassChart';
import { INVALID_FULL_DURATION, REFRESH_INTERVAL_RUNNING } from './SyndicationHelper';
import { Breadcrumb } from '../Breadcrumb';
import { HeadlineBackButton } from '../HeadlineBackButton';
import { BetterAlert } from '../../BetterAlert';
import { EnvironmentIconForSiteAsNugget } from '../../Icons';
import { SiteName } from '../../SiteName';
import { WithSyndication } from '../../WithSyndication';
import { SyndicationTargets } from './SyndicationTargets';
import { SyncCoreFeatureFlagGate } from '../../SyncCoreFeatureFlagGate';
import { FEATURE_SYNDICATION_TRACE_AVAILABLE } from '../../../features';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { KebabMenu, MenuButton } from '../../KebabMenu';
import { WithSite } from '../../WithSite';
import { AppParamsContext, IAppParams } from '../../AppParams';
import { WithCreateSupportTicketUrl, getAppLinkForContent, getAppLinkForTask } from '../AppLink';
import { IsBackendGate } from '../../SiteFeatureFlagGate';

export function SyndicationDetails({
  syndication,
  setSyndication,
  showSiteName,
  noMassUpdates,
  noMassUpdatesName,
  noMigration,
  noMigrationName,
  migrationName,
  migration,
  setSyndicationAndErrorDetails,
}: {
  syndication: ClientSyndicationEntity;
  setSyndication: (syndication?: ClientSyndicationEntity) => void;
  showSiteName?: boolean;
  noMassUpdates?: () => void;
  noMassUpdatesName?: string;
  noMigration?: () => void;
  noMigrationName?: string;
  migrationName?: string;
  migration?: ClientMigrationEntity;
  setSyndicationAndErrorDetails: (
    syndication?: ClientSyndicationEntity,
    errorDetails?: { operationIndex: number; errorIndex: number }
  ) => void;
}) {
  const [refreshSelectedSyndication, setRefreshSelectedSyndication] = useState(0);
  const [confirm, setConfirm] = useState('');
  const [tracing, setTracing] = useState<'unknown' | 'none' | 'this' | 'next'>(syndication.trace ? 'this' : 'unknown');
  const mounted = useRef(false);
  const api = useContext(SyncCoreApiContext);

  const canRestart = syndication.type !== SyndicationType.RetrieveEntityList && syndication.type !== SyndicationType.RetrieveConfig;

  const canTraceSyndication = !!syndication.rootEntityType?.getId() && !!syndication.rootEntityReference;
  const canTrace = canTraceSyndication && tracing === 'none';
  const canTraceThis = canTrace && syndication.isActive;
  const canTraceNext = canTrace && !syndication.isActive;
  const canTraceNone = tracing === 'next' || tracing === 'this';

  const languages = syndication.changedLanguages || syndication.usage?.translations.map((c) => c.language);

  const latestError = getLatestError(syndication);

  const isPush = [
    SyndicationType.RetrieveAndDeleteEmbeddedEntity,
    SyndicationType.RetrieveAndDeleteEntity,
    SyndicationType.RetrieveAndPushEntity,
    SyndicationType.RetrieveEntity,
    SyndicationType.RetrieveEntityList,
  ].includes(syndication.type);

  useEffect(() => {
    mounted.current = true;

    selectSyndication(syndication, true);

    if (tracing === 'unknown') {
      if (canTraceSyndication) {
        api?.api.syndication.remoteEntityUsages
          .entityUsageForSite(
            {
              entityTypeId: syndication.rootEntityType!.getId(),
              siteId: syndication.targetSite.getId(),
            },
            syndication.rootEntityReference?.remoteUniqueId
              ? {
                  remoteUniqueId: syndication.rootEntityReference.remoteUniqueId,
                }
              : {
                  remoteUuid: syndication.rootEntityReference!.remoteUuid!,
                }
          )
          .then((usage) => {
            setTracing(usage?.traceNext ? 'next' : 'none');
          });
      } else {
        setTracing('none');
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [syndication.id]);

  function selectSyndication(selectedSyndication?: ClientSyndicationEntity, forceUpdate?: boolean) {
    if (refreshSelectedSyndication) {
      clearTimeout(refreshSelectedSyndication);
      setRefreshSelectedSyndication(0);
    }

    if (selectedSyndication) {
      // Sometimes the summary isn't updated quickly enough, so we can't use the
      // migration status itself here.
      if (!doneStatus.includes(selectedSyndication.status) || forceUpdate) {
        const timeout = setTimeout(async () => {
          if (!mounted.current) {
            return;
          }

          const updatedSyndication = await api?.api.syndication.syndications.item(selectedSyndication.id, true, { includeUsage: true });
          selectSyndication(updatedSyndication);
        }, REFRESH_INTERVAL_RUNNING);

        setRefreshSelectedSyndication(timeout as any);
      }
    }

    if (syndication !== selectedSyndication) {
      setSyndication(selectedSyndication);
    }
  }

  async function trace(trace: 'this' | 'next' | 'none') {
    await api?.api.syndication.syndications.trace(syndication.id, trace);

    setTracing(trace);
  }

  const languageNugget = languages?.length ? (
    <Nugget dark icon={faLanguage}>
      {languages[0]}
      {languages.length > 1 ? ` +${languages.length - 1}` : null}
    </Nugget>
  ) : null;

  const poolNugget = syndication.pools?.length ? (
    <Nugget dark icon={faLayerGroup}>
      <PoolName id={syndication.pools[0].getId()} inline />
      {syndication.pools.length > 1 ? ` +${syndication.pools.length - 1}` : null}
    </Nugget>
  ) : null;

  let actions: React.ReactNode[] = [
    ...(syndication.rootEntityDetails?.viewUrl && !isPush
      ? [
          <span className="fs-6 align-middle mx-3">
            <ExternalLinkWithIcon to={syndication.rootEntityDetails.viewUrl}>View source</ExternalLinkWithIcon>
          </span>,
        ]
      : []),
    <SyncCoreFeatureFlagGate
      featureName={FEATURE_SYNDICATION_TRACE_AVAILABLE}
      ifEnabled={() => (
        <>
          {canTraceThis && <MenuButton onClick={() => trace('this')}>Trace this</MenuButton>}
          {canTraceNext && <MenuButton onClick={() => trace('next')}>Trace next</MenuButton>}
          {canTraceNone && <MenuButton onClick={() => trace('none')}>Disable trace</MenuButton>}
        </>
      )}
    />,
    ...(!syndication.isActive && canRestart
      ? [
          <MenuButton onClick={() => setConfirm('retry')}>
            {syndication.status === SyndicationStatus.Finished ? 'Re-run' : 'Retry'}
          </MenuButton>,
        ]
      : []),
    <WithSite entityId={syndication.targetSite.getId()}>
      {(site) =>
        site && syndication.rootEntityReference?.remoteUuid ? (
          <ExternalLinkWithIcon
            to={getAppLinkForContent(syndication.rootEntityReference?.remoteUuid, site.uuid)}
            className={'text-decoration-none text-muted fs-5 d-block px-3 py-2'}
          >
            Show Content
          </ExternalLinkWithIcon>
        ) : null
      }
    </WithSite>,
    <WithSite entityId={syndication.targetSite.getId()}>
      {(site) =>
        site ? (
          <IsBackendGate
            ifDisabled={() => (
              <ExternalLinkWithIcon
                to={getAppLinkForTask(syndication.id, site.uuid)}
                className={'text-decoration-none text-muted fs-5 d-block px-3 py-2'}
              >
                Open in App
              </ExternalLinkWithIcon>
            )}
          />
        ) : null
      }
    </WithSite>,
    <WithSite entityId={syndication.targetSite.getId()}>
      {(site) =>
        site ? (
          <WithCreateSupportTicketUrl taskUrl={getAppLinkForTask(syndication.id, site.uuid)}>
            {(createTicketUrl) => (
              <ExternalLinkWithIcon to={createTicketUrl} className={'text-decoration-none text-muted fs-5 d-block px-3 py-2'}>
                Contact Support
              </ExternalLinkWithIcon>
            )}
          </WithCreateSupportTicketUrl>
        ) : null
      }
    </WithSite>,
  ];

  const menuItems = actions.length ? (
    <Dropdown.Menu className="shadow-sm">
      {actions.map((c, index) => (
        <Dropdown.Item className="p-0" key={index} as={'div'}>
          {c}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  ) : null;

  return (
    <>
      <div className="">
        {noMigrationName || noMassUpdatesName || migrationName ? (
          <Breadcrumb
            items={[
              ...(noMassUpdatesName && noMassUpdates ? [{ name: noMassUpdatesName, onClick: () => noMassUpdates() }] : []),
              ...(noMigrationName && noMigration ? [{ name: noMigrationName, onClick: () => noMigration() }] : []),
              ...(migrationName ? [{ name: migrationName, onClick: () => setSyndication() }] : []),
              { name: getSyndicationTypeName(syndication.type, syndication.rootEntityReference?.name) },
            ]}
          />
        ) : null}
        <LeftRightH1
          left={
            <>
              <HeadlineBackButton onClick={() => setSyndication()} />
              <span className="d-inline-block align-middle fs-2 ms-2 me-2">
                <SyndicationStatusIcon status={syndication.status} type={syndication.type} background="none" />
              </span>
              {syndication.skipUnchanged && (
                <span
                  className="me-2 ms-0 d-inline-block align-middle fs-2"
                  title="Unchanged entities are not updated to improve the performance of this update."
                >
                  <FontAwesomeIcon icon={faGaugeMax} />
                </span>
              )}
              <span className="d-inline-block align-middle">
                <SyndicationName entity={syndication} showType showSiteName={showSiteName} />
                {syndication.rootEntityReference?.name ? (
                  <span className="fw-normal">
                    {' '}
                    {syndication.usage?.viewUrl ? (
                      <ExternalLinkWithIcon to={syndication.usage.viewUrl}>{syndication.rootEntityReference.name}</ExternalLinkWithIcon>
                    ) : syndication.rootEntityDetails?.viewUrl ? (
                      <ExternalLinkWithIcon to={syndication.rootEntityDetails.viewUrl}>
                        {syndication.rootEntityReference.name}
                      </ExternalLinkWithIcon>
                    ) : (
                      syndication.rootEntityReference.name
                    )}
                  </span>
                ) : undefined}
              </span>
            </>
          }
          right={
            syndication ? (
              <Row>
                {syndication.isActive ? (
                  <Col>
                    <Button variant="danger" className="ms-2 pt-2 px-4 pb-2 bg-white shadow-sm" onClick={() => setConfirm('abort')}>
                      Abort
                    </Button>
                  </Col>
                ) : null}
                <Col>{menuItems ? <KebabMenu>{menuItems}</KebabMenu> : null}</Col>
              </Row>
            ) : null
          }
        />
      </div>

      <div className="mt-3 mb-3">
        <Nugget icon={faClock} dark>
          {getDateName(syndication.createdAt)}, {syndication.createdAt.format('LT')}
        </Nugget>
        {syndication.dependsOnSyndication?.getId() ? (
          <Nugget icon={faBolt} dark>
            <WithSyndication entityId={syndication.dependsOnSyndication?.getId()}>
              {(dependsOnSyndication) => (
                <span
                  className="text-decoration-underline cursor-pointer d-inline-block align-top"
                  onClick={() => setSyndication(dependsOnSyndication)}
                >
                  <SiteName inline id={dependsOnSyndication?.targetSite.getId()!} />
                </span>
              )}
            </WithSyndication>
          </Nugget>
        ) : null}
        {showSiteName && <EnvironmentIconForSiteAsNugget fallbackIcon={faBrowser} siteId={syndication.targetSite.getId()} dark />}
        {syndication.flow ? (
          <Nugget icon={faSitemap} dark rotateIcon={90}>
            <FlowName id={syndication.flow.getId()!} inline />
          </Nugget>
        ) : null}
        {syndication.pools?.length ? (
          syndication.pools.length > 1 ? (
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => (
                <Tooltip id={`tooltip-languages-${syndication.id}`} {...props} className="tooltip-wide">
                  <ul>
                    {syndication.pools.map((c) => (
                      <li key={c.getId()}>
                        {' '}
                        <PoolName id={c.getId()} inline />
                      </li>
                    ))}
                  </ul>
                </Tooltip>
              )}
            >
              <span>{poolNugget}</span>
            </OverlayTrigger>
          ) : (
            poolNugget
          )
        ) : undefined}
        {syndication.rootEntityTypeVersion ? (
          <WithEntityTypeVersion entityId={syndication.rootEntityTypeVersion.getId()!}>
            {(entityTypeVersion) => (
              <Nugget
                dark
                icon={
                  getEntityTypeIcon(entityTypeVersion.appType, entityTypeVersion.namespaceMachineName, entityTypeVersion.machineName).icon
                }
              >
                {entityTypeVersion.name}
              </Nugget>
            )}
          </WithEntityTypeVersion>
        ) : null}
        {languages?.length ? (
          languages.length > 1 ? (
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => (
                <Tooltip id={`tooltip-languages-${syndication.id}`} {...props} className="tooltip-wide">
                  <ul>
                    {languages.map((c) => (
                      <li key={c}>{c}</li>
                    ))}
                  </ul>
                </Tooltip>
              )}
            >
              <span>{languageNugget}</span>
            </OverlayTrigger>
          ) : (
            languageNugget
          )
        ) : null}
        {typeof syndication.duration === 'number' && (
          <OverlayTrigger
            placement="bottom"
            overlay={(props) => (
              <Tooltip id={`tooltip-time-and-duration-${syndication.id}`} {...props} className="tooltip-wide">
                <ul>
                  <li>Started: {syndication.createdAt.format('LLL')}</li>
                  {typeof syndication.duration === 'number' && <li>Duration: {formatDuration(syndication.duration)}</li>}
                  {typeof syndication.fullDuration === 'number' &&
                    syndication.isAutoUpdate &&
                    syndication.fullDuration < INVALID_FULL_DURATION && (
                      <li>Start to finish: {formatDuration(syndication.fullDuration)}</li>
                    )}
                  {syndication.rdCount === 1
                    ? typeof syndication.rdTotal === 'number' && <li>Request time: {formatDuration(syndication.rdTotal)}</li>
                    : !!syndication.rdCount && (
                        <li>
                          Requests:
                          <ul>
                            {typeof syndication.rdCount === 'number' && <li>Count: {syndication.rdCount}</li>}
                            {typeof syndication.rdTotal === 'number' && <li>Total time: {formatDuration(syndication.rdTotal)}</li>}
                            {typeof syndication.rdAverage === 'number' && <li>Average time: {formatDuration(syndication.rdAverage)}</li>}
                            {typeof syndication.rdShortest === 'number' && <li>Shortest time: {formatDuration(syndication.rdShortest)}</li>}
                            {typeof syndication.rdLongest === 'number' && <li>Longest time: {formatDuration(syndication.rdLongest)}</li>}
                          </ul>
                        </li>
                      )}
                </ul>
              </Tooltip>
            )}
          >
            <span>
              <Nugget
                dark
                icon={faGauge}
                color={
                  syndication.duration > DURATION_DANGER ||
                  (syndication.fullDuration && syndication.fullDuration < INVALID_FULL_DURATION ? syndication.fullDuration : 0) >
                    FULL_DURATION_DANGER
                    ? 'danger'
                    : syndication.duration > DURATION_WARNING ||
                        (syndication.fullDuration && syndication.fullDuration < INVALID_FULL_DURATION ? syndication.fullDuration : 0) >
                          FULL_DURATION_WARNING
                      ? 'warning'
                      : undefined
                }
              >
                <span
                  className={
                    syndication.duration > DURATION_DANGER
                      ? 'text-danger'
                      : syndication.duration > DURATION_WARNING
                        ? 'text-warning'
                        : 'text-muted'
                  }
                >
                  {formatDuration(syndication.duration)}
                </span>
                {typeof syndication.fullDuration === 'number' &&
                  syndication.isAutoUpdate &&
                  syndication.fullDuration < INVALID_FULL_DURATION && (
                    <>
                      <span className="text-muted"> / </span>
                      <span
                        className={
                          syndication.fullDuration > FULL_DURATION_DANGER
                            ? 'text-danger'
                            : syndication.fullDuration > FULL_DURATION_WARNING
                              ? 'text-warning'
                              : 'text-muted'
                        }
                      >
                        {formatDuration(syndication.fullDuration)}
                      </span>
                      <span className="text-muted"> total</span>
                    </>
                  )}
              </Nugget>
            </span>
          </OverlayTrigger>
        )}
        {tracing === 'this' || tracing === 'next' ? (
          <Nugget dark icon={faMagnifyingGlassChart}>
            {tracing}
          </Nugget>
        ) : null}
        <Nugget
          dark
          icon={faExclamation}
          color={syndication.priority && syndication.priority > SyndicationPriority.Normal ? 'danger' : undefined}
        >
          {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
            'SyndicationPriority',
            (syndication.priority ?? SyndicationPriority.Normal) as any
          )}
        </Nugget>
      </div>

      {confirm && (
        <EmbeddedModal show onHide={() => setConfirm('')}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirm === 'abort' || confirm === 'abort-loading' ? (
              <>
                <div className="text-danger">
                  <strong>This will stop the update but only if the request hasn't started yet.</strong>
                </div>
                <div className="mt-2 mb-2">
                  As Content Sync works completely asynchronously, the status may still change if the request to your site was already in
                  progress. You may want to refresh in a couple of seconds to see if that's the case.
                </div>
                <Right className="pe-3">
                  <Button
                    variant="danger"
                    disabled={confirm === 'abort-loading'}
                    onClick={async () => {
                      setConfirm('abort-loading');

                      try {
                        const [update] = await api!.api.syndication.syndications.abort([syndication.id]);
                        selectSyndication(update);
                      } finally {
                        setConfirm('');
                      }
                    }}
                  >
                    Abort
                  </Button>
                </Right>
              </>
            ) : (
              <>
                <div className="text-warning">
                  <strong>
                    This will restart the operation from it's previous state. So if there was a content update in the meantime this may
                    propagate an older revision of the content.
                  </strong>
                </div>
                <div className="mt-2 mb-2">Please double check that this is the latest possible update you can retry.</div>
                <Right className="pe-3">
                  <Button
                    variant="light"
                    disabled={confirm === 'retry-loading'}
                    onClick={async () => {
                      setConfirm('retry-loading');

                      try {
                        const update = await api!.api.syndication.syndications.restart(syndication.id);
                        await selectSyndication(update);
                      } finally {
                        setConfirm('');
                      }
                    }}
                  >
                    Re-run
                  </Button>
                </Right>
              </>
            )}
          </Modal.Body>
        </EmbeddedModal>
      )}

      {latestError && (
        <div className="mt-5 mb-5 ms-1">
          <BetterAlert
            variant="danger"
            headline="Something went wrong."
            action={{
              perform: () => setSyndicationAndErrorDetails(syndication, latestError),
              label: 'View Error',
            }}
          >
            At least one operation failed.
          </BetterAlert>
        </div>
      )}

      {syndication.operations?.length ? (
        <div className="mt-4">
          <h3>Operations</h3>
          <div className="bg-white rounded p-2 pt-3" style={{ position: 'relative' }}>
            <div
              style={{ position: 'absolute', borderLeft: '2px dashed #d4d4d4', left: '28px', top: '30px', bottom: '20px', width: '0' }}
            ></div>
            {syndication.operations?.map((operation, operationIndex) => (
              <OperationListItem
                key={operationIndex}
                operation={operation}
                onErrorDetails={(errorIndex) =>
                  setSyndicationAndErrorDetails(syndication, errorIndex === undefined ? undefined : { operationIndex, errorIndex })
                }
              />
            ))}
          </div>
        </div>
      ) : syndication.status === SyndicationStatus.Initializing ? (
        <Alert>Initializing... Please wait a moment.</Alert>
      ) : syndication.status === SyndicationStatus.Aborted ? (
        <Alert variant="light">This update has been aborted.</Alert>
      ) : syndication.status === SyndicationStatus.Finished &&
        !!syndication.operations &&
        syndication.type === SyndicationType.PushEntity ? (
        <Alert variant="light">No update required.</Alert>
      ) : undefined}

      {[
        SyndicationType.RetrieveAndDeleteEmbeddedEntity,
        SyndicationType.RetrieveAndDeleteEntity,
        SyndicationType.RetrieveAndPushEntity,
      ].includes(syndication.type) && (
        <SyndicationTargets
          sourceSyndicationId={syndication.id}
          setSyndication={setSyndication}
          setSyndicationAndErrorDetails={setSyndicationAndErrorDetails}
        />
      )}
    </>
  );
}

import { IconButton, LeftRightContainer, Right } from '@edgebox/react-components';
import { FlowSyndicationMode, MigrationType, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import { ClientFlowEntity, ClientMigrationEntity, ClientMigrationEntityWithSummary } from '@edgebox/sync-core-rest-client';
import { FlowSummary } from '@edgebox/sync-core-rest-client';
import { faCloudDownloadAlt } from '@fortawesome/pro-duotone-svg-icons/faCloudDownloadAlt';
import { faCloudUploadAlt } from '@fortawesome/pro-duotone-svg-icons/faCloudUploadAlt';
import { faDebug } from '@fortawesome/pro-duotone-svg-icons/faDebug';
import { faExchangeAlt } from '@fortawesome/pro-duotone-svg-icons/faExchangeAlt';
import { faHistory } from '@fortawesome/pro-duotone-svg-icons/faHistory';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faExclamation } from '@fortawesome/pro-light-svg-icons/faExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { EntityTypeVersionName, WithEntityTypeVersion } from '../../EntityTypeVersionName';
import { PagedMigrationList } from '../../PagedMigrationList';
import { STATUS_FILTERS, StatusFilter } from './SyndicationHelper';
import { InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';

const MigrationTypeItem = ({
  name,
  description,
  warning,
  selected,
  icon,
  type,
  onSelect,
}: {
  name: string;
  description: React.ReactNode;
  warning?: React.ReactNode;
  icon: React.ReactNode;
  selected: boolean;
  type: MigrationType;
  onSelect: () => void;
}) => (
  <Row className="mb-2 cursor-pointer" onClick={onSelect}>
    <Col xs={1} className="text-center align-items-stretch d-flex">
      <div className="d-flex flex-row align-items-center justify-content-center" style={{ width: '100%' }}>
        <div>
          <Form.Check
            style={{ height: '1.5rem' }}
            className="align-self-center m-0"
            id={`migrate-${type}`}
            checked={selected}
            type={'radio'}
            label={''}
          />
        </div>
      </div>
    </Col>
    <Col xs={2} className="text-center">
      <div>{icon}</div>
    </Col>
    <Col>
      <p>
        <strong>{name}</strong>
      </p>
      <p>{description}</p>
      {warning && <p className="text-danger">{warning}</p>}
    </Col>
  </Row>
);

interface IMigrationListProps {
  selectedFlowMachineName?: string;
  startNew?: boolean;
  forbidStartNew?: boolean;
  viewOnly?: boolean;
  siteId?: InternalId;
  showSiteName?: boolean;
  onSelectItem?: (migration: ClientMigrationEntityWithSummary) => void;
}
interface IMigrationListState extends ISyncCoreApiComponentState {
  statusFilter?: StatusFilter | 'initial';
  opreationsStatusFilter?: StatusFilter;
  flows?: FlowSummary[];
  selectedFlow?: FlowSummary;
  flowEntity?: ClientFlowEntity;
  createNew?: boolean;
  createNewOfType?: MigrationType;
  createNewForEntityTypeVersions?: InternalId[];
  createNewMigrations?: ClientMigrationEntity[];
  createNewSubmitted?: boolean;
  hasUpdateLimit?: boolean;
  refreshedAt?: number;
  confirm?: 'abort' | 'abort-loading';
}
export class MigrationList extends SyncCoreApiComponent<IMigrationListProps, IMigrationListState> {
  async load() {
    const contractConfiguration = await this.api.utility.configuration.contract();

    const site = await this.getCurrentSite(true);

    let flows: FlowSummary[] = [];
    let page = 0;
    let response: IPagedListResponse<FlowSummary>;
    do {
      response = await this.api.syndication.flows.search(
        {
          siteId: site.id,
        },
        { page }
      );
      flows = flows.concat(response.items);
      page++;
    } while (page < response.numberOfPages);

    const selectedFlow = flows.find((c) => c.machineName === this.props.selectedFlowMachineName);
    const flowEntity = selectedFlow ? await this.api.syndication.flows.item(selectedFlow.id) : undefined;

    return {
      flows,
      hasUpdateLimit: !!contractConfiguration.maxUpdates,
      selectedFlow,
      flowEntity,
      createNew: this.props.startNew,
      opreationsStatusFilter: 'all' as StatusFilter,
    };
  }

  render() {
    const {
      statusFilter,
      createNew,
      createNewOfType,
      selectedFlow,
      flowEntity,
      flows,
      hasUpdateLimit,
      createNewForEntityTypeVersions,
      createNewMigrations,
      createNewSubmitted,
      refreshedAt,
      opreationsStatusFilter,
      confirm,
    } = this.state;
    const { forbidStartNew, viewOnly, siteId, onSelectItem, showSiteName } = this.props;

    const isPushing = !!flowEntity?.sitePushes?.length;
    const isPulling = !!flowEntity?.sitePulls?.length;
    const isPushingManually = !!flowEntity?.sitePushes?.find((c) => c.mode === FlowSyndicationMode.Manually);

    return (
      <>
        {viewOnly ? undefined : (
          <Right className="me-2">
            <Button
              variant="primary"
              onClick={() =>
                this.setState({
                  createNew: true,
                  createNewOfType: undefined,
                  createNewForEntityTypeVersions: undefined,
                  createNewMigrations: undefined,
                  createNewSubmitted: undefined,
                })
              }
              disabled={forbidStartNew || !flows?.length}
              title={forbidStartNew ? 'Please use the migration UI to push and pull content or switch over to v2 to use this.' : undefined}
              className="start-new px-4 py-2 fs-5"
            >
              Start new
            </Button>
          </Right>
        )}

        <Modal
          show={createNew && !flowEntity}
          scrollable
          onHide={() =>
            this.setState({
              flowEntity: undefined,
              createNew: undefined,
              createNewOfType: undefined,
              createNewForEntityTypeVersions: undefined,
              createNewMigrations: undefined,
              createNewSubmitted: undefined,
            })
          }
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-truncate mw-100">Start mass update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {flows?.map((flowSummary) => (
              <Form.Check
                key={flowSummary.id}
                style={{ height: '1.5rem' }}
                className="align-self-center m-0 cursor-pointer mb-2"
                id={`flow-${flowSummary.machineName}`}
                checked={flowSummary.id === selectedFlow?.id}
                type={'radio'}
                label={flowSummary.name}
                onChange={() => this.setState({ selectedFlow: flowSummary })}
              />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={!selectedFlow}
              onClick={async () => {
                const flowEntity = await this.api.syndication.flows.item(selectedFlow!.id);
                this.setState({
                  flowEntity,
                });
              }}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={createNew && flowEntity && !createNewForEntityTypeVersions}
          scrollable
          onHide={() =>
            this.setState({
              flowEntity: undefined,
              createNew: undefined,
              createNewOfType: undefined,
              createNewForEntityTypeVersions: undefined,
              createNewMigrations: undefined,
              createNewSubmitted: undefined,
            })
          }
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-truncate mw-100">
              <strong>{flowEntity?.name || null}</strong>: Start mass update
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isPushing && (
              <MigrationTypeItem
                name="Push failed"
                description="Push all content from this site that failed to be pushed before."
                type={MigrationType.PushFailed}
                icon={<FontAwesomeIcon icon={faDebug} size="6x" className="text-muted align-middle" />}
                selected={createNewOfType === MigrationType.PushFailed}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PushFailed })}
              />
            )}
            {isPushing && (
              <MigrationTypeItem
                name="Push all"
                description="Push all content from this site that has been pushed before. You should only need this to setup new Sync Cores."
                warning={
                  <>
                    <strong>Use with caution:</strong> This can create significant load on your site.{' '}
                    {isPushingManually && (
                      <>
                        If you have pushed content manually before, their <strong>latest revision will be pushed</strong>.
                      </>
                    )}
                  </>
                }
                icon={<FontAwesomeIcon icon={faCloudUploadAlt} size="6x" className="text-muted align-middle" />}
                type={MigrationType.PushAll}
                selected={createNewOfType === MigrationType.PushAll}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PushAll })}
              />
            )}
            {isPushing && (
              <MigrationTypeItem
                name="Push all, even new"
                description={
                  <>
                    Push all content from this site even if it has been pushed before or is set to be pushed manually and{' '}
                    <strong>has not been manually pushed yet</strong>. You should only need this to setup new sites.
                  </>
                }
                warning={
                  <>
                    <strong>Use with caution:</strong> This can create significant load on your site and it may publish content your editors
                    didn't want to publish yet.
                  </>
                }
                icon={
                  <span className="fa-stack fa-4x align-middle">
                    <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" className="text-muted fa-stack-1x" />
                    <span className="fa-stack fa-xs">
                      <FontAwesomeIcon icon={faExclamation} size="xs" className="text-danger fa-stack-xs" transform="down-4 right-10" />
                    </span>
                  </span>
                }
                type={MigrationType.PushAllLatest}
                selected={createNewOfType === MigrationType.PushAllLatest}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PushAllLatest })}
              />
            )}

            {isPulling && (
              <MigrationTypeItem
                name="Pull failed"
                description="Pull all content into this site that failed to be pulled before."
                type={MigrationType.PullFailed}
                icon={<FontAwesomeIcon icon={faDebug} size="6x" className="text-muted align-middle" />}
                selected={createNewOfType === MigrationType.PullFailed}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PullFailed })}
              />
            )}
            {isPulling && (
              <MigrationTypeItem
                name="Pull changed"
                description="Pull all content into this site that matches the Flow filters unless the latest version has been pulled successfully before."
                type={MigrationType.PullChanged}
                icon={<FontAwesomeIcon icon={faHistory} size="6x" className="text-muted align-middle" flip="horizontal" />}
                selected={createNewOfType === MigrationType.PullChanged}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PullChanged })}
              />
            )}
            {isPulling && hasUpdateLimit && (
              <MigrationTypeItem
                name="Retry limited"
                description="Pull all content into this site that would have been pulled before if your contract had allowed more updates."
                type={MigrationType.PullAllLimitExceeded}
                icon={<FontAwesomeIcon icon={faRedo} size="6x" className="text-muted align-middle" />}
                selected={createNewOfType === MigrationType.PullAllLimitExceeded}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PullAllLimitExceeded })}
              />
            )}
            {isPulling && (
              <MigrationTypeItem
                name="Map existing"
                description={
                  <>
                    Map all existing content from this site to content from other sites. Content will be mapped using their UUID for content
                    entities and their ID for configuration entities. This should only be required when setting up new sites.{' '}
                    <strong>Push all your content from your publishing sites first before using this.</strong>
                  </>
                }
                warning={
                  <>
                    <strong>Use with caution:</strong> This can create significant load on your site.
                  </>
                }
                type={MigrationType.MapExistingById}
                icon={<FontAwesomeIcon icon={faExchangeAlt} size="6x" className="text-muted align-middle" />}
                selected={createNewOfType === MigrationType.MapExistingById}
                onSelect={() => this.setState({ createNewOfType: MigrationType.MapExistingById })}
              />
            )}
            {isPulling && (
              <MigrationTypeItem
                name="Pull all"
                description="Pull all content into this site that matches the Flow filters even if it has been pulled before. You should only need this to setup new sites."
                warning={
                  <>
                    <strong>Use with caution:</strong> This can create significant load on your site.
                  </>
                }
                type={MigrationType.PullAll}
                icon={<FontAwesomeIcon icon={faCloudDownloadAlt} size="6x" className="text-muted align-middle" />}
                selected={createNewOfType === MigrationType.PullAll}
                onSelect={() => this.setState({ createNewOfType: MigrationType.PullAll })}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <LeftRightContainer
              className="flex-grow-1"
              left={
                <IconButton variant="light" icon={faChevronLeft} onClick={() => this.setState({ flowEntity: undefined })}>
                  Back
                </IconButton>
              }
              right={
                <Button
                  variant="primary"
                  disabled={!createNewOfType}
                  onClick={() => this.setState({ createNewForEntityTypeVersions: [], createNewMigrations: [] })}
                >
                  Continue
                </Button>
              }
            />
          </Modal.Footer>
        </Modal>
        <Modal
          show={createNew && !!createNewForEntityTypeVersions}
          scrollable
          onHide={() =>
            this.setState({
              flowEntity: undefined,
              createNew: undefined,
              createNewOfType: undefined,
              createNewForEntityTypeVersions: undefined,
              createNewMigrations: undefined,
              createNewSubmitted: undefined,
            })
          }
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>{SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', createNewOfType!)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {createNewForEntityTypeVersions
              ? (() => {
                  let allTypes = ((isPushing ? flowEntity?.sitePushes : flowEntity?.sitePulls) || [])
                    .filter((c) => c.mode !== FlowSyndicationMode.Dependent)
                    .map((config) => config.entityTypeVersions.map((type) => ({ type, pool: config.pool, mode: config.mode })))
                    .flat();
                  allTypes = allTypes.filter((a, i) => allTypes.findIndex((b) => a.type.getId() === b.type.getId()) === i);
                  return allTypes.map(({ type, pool, mode }, typeIndex) => (
                    <WithEntityTypeVersion
                      entityId={type.getId()}
                      loading={() => (
                        <Form.Check
                          key={`entity-type-loading-${type.getId()}`}
                          id={`entity-type-loading-${type.getId()}`}
                          checked={true}
                          disabled={true}
                          type={'checkbox'}
                          label={'...'}
                        />
                      )}
                    >
                      {(entityTypeVersion) => (
                        <Form.Check
                          key={`entity-type-${entityTypeVersion.namespaceMachineName}-${entityTypeVersion.machineName}`}
                          id={`entity-type-${entityTypeVersion.namespaceMachineName}-${entityTypeVersion.machineName}`}
                          checked={createNewForEntityTypeVersions!.includes(type.getId())}
                          onChange={(e) =>
                            this.setState({
                              createNewForEntityTypeVersions: e.target.checked
                                ? createNewForEntityTypeVersions!.concat([type.getId()])
                                : createNewForEntityTypeVersions!.filter((c) => c !== type.getId()),
                            })
                          }
                          disabled={createNewSubmitted}
                          type={'checkbox'}
                          label={
                            <>
                              <EntityTypeVersionName
                                entity={entityTypeVersion}
                                count={
                                  [MigrationType.PullFailed, MigrationType.PullChanged, MigrationType.PullAllLimitExceeded].includes(
                                    createNewOfType!
                                  )
                                    ? undefined
                                    : {
                                        fromSite: isPushing,
                                        flowMachineName: flowEntity?.machineName,
                                        mode:
                                          createNewOfType === MigrationType.PushFailed
                                            ? 'push-failed'
                                            : createNewOfType === MigrationType.PushAll
                                              ? 'pushed'
                                              : 'all',
                                        poolIds: [pool.getId()],
                                      }
                                }
                                withNamespace
                              />{' '}
                              <Badge bg="light">
                                {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('FlowSyndicationMode', mode)}
                              </Badge>{' '}
                              {createNewSubmitted &&
                                createNewForEntityTypeVersions!.includes(type.getId()) &&
                                (createNewMigrations?.find((c) => c.entityTypeVersion?.getId() === type.getId()) ? (
                                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                                ) : (
                                  <FontAwesomeIcon icon={faCheck} className="text-muted" />
                                ))}
                            </>
                          }
                        />
                      )}
                    </WithEntityTypeVersion>
                  ));
                })()
              : undefined}
          </Modal.Body>
          <Modal.Footer>
            <LeftRightContainer
              className="flex-grow-1"
              left={
                <IconButton
                  variant="light"
                  icon={faChevronLeft}
                  onClick={() => this.setState({ createNewForEntityTypeVersions: undefined, createNewMigrations: undefined })}
                >
                  Back
                </IconButton>
              }
              right={
                <Button
                  variant="primary"
                  disabled={!createNewForEntityTypeVersions?.length || createNewSubmitted}
                  onClick={async () => {
                    this.setState({ createNewSubmitted: true });
                    for (const entityType of createNewForEntityTypeVersions!) {
                      const entityTypeEntity = await this.api.syndication.remoteEntityTypeVersions.item(entityType);
                      const migration = await this.api.syndication.migrations.create({
                        type: createNewOfType!,
                        entityTypeReference: {
                          machineName: entityTypeEntity.machineName,
                          namespaceMachineName: entityTypeEntity.namespaceMachineName,
                          versionId: entityTypeEntity.versionId,
                        },
                        flowMachineName: selectedFlow!.machineName,
                        // TODO: Allow for initial setup
                        initialSetup: false,
                        // TODO: Allow to set flag
                        //skipSyndication,
                        // TODO: Allow to retry failed of previous
                        //previousMigration: previous ? new StaticReference(previous) : undefined,
                        // TODO: Allow to set a date
                        //changedAfter: previous ? previous.createdAt.clone() : undefined,
                      });
                      this.setState({
                        createNewMigrations: [...this.state.createNewMigrations!, migration],
                      });
                    }

                    this.setState({ refreshedAt: Date.now() });

                    setTimeout(
                      () =>
                        this.setState({
                          flowEntity: undefined,
                          createNew: undefined,
                          createNewForEntityTypeVersions: undefined,
                          createNewMigrations: undefined,
                          createNewSubmitted: undefined,
                        }),
                      1_500
                    );
                  }}
                >
                  Start ({createNewSubmitted ? `${createNewMigrations!.length}/` : ''}
                  {createNewForEntityTypeVersions?.length || 0})
                </Button>
              }
            />
          </Modal.Footer>
        </Modal>

        {statusFilter === 'aborted' && (
          <Alert variant="light">
            Aborted updates are usually nothing to worry about. Updates are aborted if one of the following happens:
            <ul>
              <li>A user manually aborts an update.</li>
              <li>
                The entity was changed again before the update ran, so that only the newer update is run and the previous update is aborted.
              </li>
              <li>
                The site responds with 404, indicating that a specific entity is not supposed to be pushed or pulled. Check your Flow
                configuration in that case to understand why that happens.
              </li>
            </ul>
          </Alert>
        )}
        <div style={{ display: 'grid' }}>
          <PagedMigrationList
            key={refreshedAt?.toString()}
            onSelectItem={onSelectItem}
            initialSetup={statusFilter === 'initial'}
            statuses={
              statusFilter === 'initial' ? undefined : statusFilter && statusFilter !== 'all' ? STATUS_FILTERS[statusFilter] : undefined
            }
            excludePushManually
            style={{ gridArea: '1 / 1' }}
            className={'px-3 py-2'}
            siteId={siteId}
            groupByDate
            showSiteName={showSiteName}
          />
        </div>
        {/*</ContentBox>*/ null}
      </>
    );
  }
}

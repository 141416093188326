import { library } from '@fortawesome/fontawesome-svg-core';
import {
  BooleanProperty,
  ExternalServiceId,
  InternalId,
  NestedProperty,
  UnformattedTextProperty,
  Uuid,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import { Right } from '@edgebox/react-components';
import { ClientPreviewItem, ClientSiteEntity, IPreviewServiceFilters } from '@edgebox/sync-core-rest-client';
import { PullDashboardConfiguration } from '@edgebox/sync-core-rest-client';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Alert, Badge, Button, ButtonGroup, Collapse, Form, Overlay } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';
import { FEATURE_PREVIEWS } from '../../../../features';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { ContractWarnings } from '../../../ContractWarnings';
import { PagedList } from '../../../PagedList';
import { SelectSite } from '../../../SelectSite';
import { SyncCoreFeatureFlagGate } from '../../../SyncCoreFeatureFlagGate';
import { ParamsComponent, transformBool, transformDate } from '../../ParamsComponent';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { Transform, instanceToPlain } from 'class-transformer';
import { sendToParent } from '../../../../frame-messages';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import ViewModeToggle from './components/ViewModeToggle';
import { PullDashboardItem } from './components/PullDashboardItem';

library.add(faTag);
library.add(faSpinner);

// TODO: Make sure this is called once only
let hasMultiplePoolsLoading: Promise<boolean> | undefined = undefined;

// pd-viewMode(pd = PullDashboard) key
const VIEW_MODE_STORAGE_KEY = 'pd-viewMode';

class Query {
  @UnformattedTextProperty(false, 100)
  flow?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  entityTypeNamespaceMachineName?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  entityTypeMachineName?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  pool?: ExternalServiceId;

  @UuidProperty(false)
  sourceSite?: Uuid;

  @Transform(transformDate)
  publishedAfter?: moment.Moment;

  @Transform(transformDate)
  publishedBefore?: moment.Moment;

  @UnformattedTextProperty(false, 100)
  search?: string;

  //@UnformattedTextProperty(false, 5)
  //@Matches(/^(true|false|)$/)

  @BooleanProperty(false)
  @Transform(transformBool)
  deleted?: boolean;

  @BooleanProperty(false)
  @Transform(transformBool)
  deletedLocally?: boolean;

  @BooleanProperty(false)
  @Transform(transformBool)
  existsLocally?: boolean;
}

class Params {
  @BooleanProperty(false)
  viewAll?: boolean;

  @BooleanProperty(false)
  viewForSite?: boolean;

  @BooleanProperty(false)
  configurationAccess?: boolean;

  @UnformattedTextProperty(false, 1_000)
  imagePreviewJwt?: string;

  @NestedProperty(false, () => Query)
  query?: Query;
}

interface IEntityTypeOption {
  label: string;
  value: {
    name: string;
    namespaceMachineName: ExternalServiceId;
    machineName: ExternalServiceId;
  };
}
interface IEntityTypeGroupOption {
  label: string;
  options: IEntityTypeOption[];
}

interface IPoolOption {
  label: string;
  value: ExternalServiceId;
}
type IFlowOption = IPoolOption;

interface IProps {
  params: Params;
}

interface IStateFilters {
  selectedFlow?: IFlowOption;
  selectedEntityType?: IEntityTypeOption;
  selectedPool?: IPoolOption;
  selectedSourceSite?: ClientSiteEntity;

  publishedAfter?: moment.Moment;
  publishedBefore?: moment.Moment;

  search?: string;

  deleted?: boolean;
  deletedLocally?: boolean;
  existsLocally?: boolean;
}
interface IState extends ISyncCoreApiComponentState {
  configuration?: PullDashboardConfiguration;
  allEntityTypeOptions?: IEntityTypeOption[];
  allPoolOptions?: IPoolOption[];

  showAdvancedFilters?: boolean;

  publishedAfterFocused?: boolean;
  publishedBeforeFocused?: boolean;

  flowOptions?: IFlowOption[];

  entityTypeOptions?: IEntityTypeOption[] | IEntityTypeGroupOption[];

  poolOptions?: IPoolOption[];

  defaultFilters?: IStateFilters;
  filters?: IStateFilters;

  statusFiltersOpen?: boolean;

  pullingEntityId?: InternalId;

  reset?: number;

  query?: Query;

  viewMode?: 'list' | 'preview';
}

const NUMBER_OF_ITEMS_PER_PAGE_OPTIONS = [1, 5, 10, 25];
const DEFAULT_NUMBER_OF_ITEMS_PER_PAGE = NUMBER_OF_ITEMS_PER_PAGE_OPTIONS[1];

const FIXED_NAMESPACE_NAMES: { [key: string]: string } = {
  node: 'Content',
};

export class PullDashboardWithParams extends SyncCoreApiComponent<IProps, IState> {
  private statusFiltersButton = React.createRef<any>();

  get isBackendEmbed(): boolean {
    return !!(this.props.params.viewAll || this.props.params.viewForSite);
  }

  get defaultStatusFilterName(): string {
    if (this.props.params.viewAll) {
      return 'All';
    }
    if (this.props.params.viewForSite) {
      return 'Local';
    }
    return 'New';
  }

  async load(): Promise<Partial<IState>> {
    let configuration: PullDashboardConfiguration;
    if (this.isBackendEmbed) {
      configuration = {
        flows: [],
      };
    } else {
      configuration = await this.api.utility.configuration.pullDashboard();
    }

    const flowOptions = configuration?.flows.map((c) => ({ label: c.name, value: c.machineName }));
    const selectedFlow = this.isBackendEmbed
      ? undefined
      : this.props.params.query?.flow
        ? flowOptions!.find((c) => c.value === this.props.params.query?.flow) || flowOptions![0]
        : flowOptions![0];

    const flow = selectedFlow ? configuration!.flows.find((c) => c.machineName === selectedFlow.value) : undefined;
    const allEntityTypeOptions: IEntityTypeOption[] = flow
      ? flow.entityTypes.map((value) => ({ label: value.name, value }))
      : (await this.api.syndication.remoteEntityTypes.list()).map((value) => ({ label: value.name, value }));
    const allPoolOptions: IPoolOption[] = flow
      ? flow.pools.map((c) => ({ label: c.name, value: c.machineName }))
      : (await this.api.syndication.pools.list()).map((c) => ({ label: c.name, value: c.machineName }));

    // For pulling: By default we only show content that doesn't exist locally yet and that has not been deleted yet.
    // For "view all": Ignore "exists locally" but also hide deleted content by default.
    const defaultFilters = {
      existsLocally: this.props.params.viewAll ? undefined : this.props.params.viewForSite ? true : false,
      deleted: false,
      deletedLocally: false,
    };

    const viewMode: any = localStorage.getItem(VIEW_MODE_STORAGE_KEY) || 'preview';

    const stateUpdate: Partial<IState> = {
      configuration,
      flowOptions,
      defaultFilters,
      filters: { ...defaultFilters },
      allEntityTypeOptions,
      allPoolOptions,
      query: this.props.params.query || {},
      viewMode,
    };

    this.setState(stateUpdate as any);

    if (Object.keys(stateUpdate.query!).length) {
      const { filters } = await this.getFiltersFromQuery(stateUpdate.query!, stateUpdate);
      stateUpdate.filters = filters;
    }

    this.updateFilters(selectedFlow, stateUpdate as any);

    return {};
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const query = this.props.params.query;
    if (this.state.filters && query && Object.keys(query).length) {
      if (!prevProps.params.query || JSON.stringify(query) !== JSON.stringify(prevProps.params.query)) {
        this.updateFiltersFromQuery(query, this.state);
      }
    }
  }

  async getFiltersFromQuery(query: Query, useState: Partial<IState>) {
    let changed = false;

    const filters = useState.filters!;

    if (query.deleted !== filters.deleted) {
      changed = true;
      filters.deleted = query.deleted;
    }
    if (query.deletedLocally !== filters.deletedLocally) {
      changed = true;
      filters.deletedLocally = query.deletedLocally;
    }
    if (query.existsLocally !== filters.existsLocally) {
      changed = true;
      filters.existsLocally = query.existsLocally;
    }
    if (query.search !== filters.search) {
      changed = true;
      filters.search = query.search;
    }
    if (query?.publishedAfter !== undefined && query.publishedAfter.valueOf() !== filters.publishedAfter?.valueOf()) {
      changed = true;
      filters.publishedAfter = query.publishedAfter;
    }
    if (query?.publishedBefore !== undefined && query.publishedBefore.valueOf() !== filters.publishedBefore?.valueOf()) {
      changed = true;
      filters.publishedBefore = query.publishedBefore;
    }

    if (
      query.entityTypeNamespaceMachineName !== filters.selectedEntityType?.value.namespaceMachineName ||
      query.entityTypeMachineName !== filters.selectedEntityType?.value.machineName
    ) {
      changed = true;
      const entityType =
        query?.entityTypeNamespaceMachineName && query?.entityTypeMachineName
          ? useState.allEntityTypeOptions?.find(
              (c) =>
                c.value.namespaceMachineName === query.entityTypeNamespaceMachineName && c.value.machineName === query.entityTypeMachineName
            )
          : undefined;
      filters.selectedEntityType = entityType;
    }
    if (query.flow !== filters.selectedFlow?.value) {
      changed = true;
      const flow = query?.flow ? useState.flowOptions?.find((c) => c.value === query.flow) : undefined;
      filters.selectedFlow = flow;
    }
    if (query.pool !== filters.selectedPool?.value) {
      changed = true;
      const pool = query?.pool ? useState.allPoolOptions?.find((c) => c.value === query.pool) : undefined;
      filters.selectedPool = pool;
    }
    if (query.sourceSite !== filters.selectedSourceSite?.uuid) {
      changed = true;
      const site = query?.sourceSite ? await this.api.billing.sites.itemByUuid(query.sourceSite) : undefined;
      filters.selectedSourceSite = site;
    }

    return {
      filters,
      changed,
    };
  }

  async updateFiltersFromQuery(query: Query, useState: Partial<IState>) {
    const { filters, changed } = await this.getFiltersFromQuery(query, useState);

    if (changed) {
      this.setState({ filters, query });
      this.updateFilters();
    }
  }

  getQueryFromFilters(previousQuery: Query, filters: IStateFilters) {
    let changed = false;
    const query = { ...previousQuery };

    if (query.deleted !== filters.deleted) {
      changed = true;
      query.deleted = filters.deleted;
    }
    if (query.deletedLocally !== filters.deletedLocally) {
      changed = true;
      query.deletedLocally = filters.deletedLocally;
    }
    if (query.existsLocally !== filters.existsLocally) {
      changed = true;
      query.existsLocally = filters.existsLocally;
    }
    if (query.search !== filters.search) {
      changed = true;
      query.search = filters.search;
    }
    if (query.publishedAfter?.valueOf() !== filters.publishedAfter?.valueOf()) {
      changed = true;
      query.publishedAfter = filters.publishedAfter;
    }
    if (query.publishedBefore?.valueOf() !== filters.publishedBefore?.valueOf()) {
      changed = true;
      query.publishedBefore = filters.publishedBefore;
    }

    if (
      query.entityTypeNamespaceMachineName !== filters.selectedEntityType?.value.namespaceMachineName ||
      query.entityTypeMachineName !== filters.selectedEntityType?.value.machineName
    ) {
      changed = true;
      query.entityTypeNamespaceMachineName = filters.selectedEntityType?.value.namespaceMachineName;
      query.entityTypeMachineName = filters.selectedEntityType?.value.machineName;
    }
    if (query.flow !== filters.selectedFlow?.value) {
      changed = true;
      query.flow = filters.selectedFlow?.value;
    }
    if (query.pool !== filters.selectedPool?.value) {
      changed = true;
      query.pool = filters.selectedPool?.value;
    }
    if (query.sourceSite !== filters.selectedSourceSite?.uuid) {
      changed = true;
      query.sourceSite = filters.selectedSourceSite?.uuid;
    }

    return {
      query: Object.assign(new Query(), query),
      changed,
    };
  }

  updateFilters<K extends keyof IState>(selectedFlow?: IFlowOption, stateUpdate?: Pick<IState, K>) {
    const state = { ...this.state, ...(stateUpdate || {}) };

    const configuration = state.configuration!;

    let filters = state.filters!;
    let { selectedEntityType, selectedPool } = filters;

    let allEntityTypeOptions = state.allEntityTypeOptions!;

    const flow = selectedFlow ? configuration!.flows.find((c) => c.machineName === selectedFlow.value) : undefined;
    if (flow) {
      allEntityTypeOptions = flow.entityTypes.map((value) => ({ label: value.name, value }));
    }

    let entityTypeOptions: IEntityTypeOption[] | IEntityTypeGroupOption[];
    let namespaces = allEntityTypeOptions.map((c) => c.value.namespaceMachineName);
    namespaces = namespaces.filter((a, index) => namespaces.indexOf(a) === index);

    // Group by namespace
    if (namespaces.length > 1) {
      entityTypeOptions = namespaces.map(
        (namespace) =>
          ({
            label:
              FIXED_NAMESPACE_NAMES[namespace] ||
              namespace.replace(/(^|[^A-Za-z0-9]+)([a-z0-9])/g, (m, $1, $2) => ($1 ? ' ' : '') + $2.toUpperCase()),
            options: allEntityTypeOptions.filter((c) => c.value.namespaceMachineName === namespace),
          }) as IEntityTypeGroupOption
      ) as IEntityTypeGroupOption[];
    } else {
      entityTypeOptions = allEntityTypeOptions;
    }

    let poolOptions: IPoolOption[] = state.allPoolOptions!;
    if (flow) {
      poolOptions = flow.pools.map((c) => ({ label: c.name, value: c.machineName }));
    }
    poolOptions = poolOptions.filter((a, index) => poolOptions.findIndex((b) => a.value === b.value) === index);

    // Unset the selected options if they are no longer available, but keep them if they are.
    filters = {
      ...filters,
      selectedFlow,
      selectedEntityType:
        selectedEntityType &&
        allEntityTypeOptions.find(
          (c) =>
            c.value.namespaceMachineName === selectedEntityType!.value.namespaceMachineName &&
            c.value.machineName === selectedEntityType!.value.machineName
        ),
      selectedPool: selectedPool && poolOptions.find((c) => c.value === selectedPool!.value),
    };

    this.setState({
      ...(stateUpdate || {}),
      entityTypeOptions,
      poolOptions,
      filters,
    });
  }

  // Function to update previewMode and localStorage
  setPreviewMode = (newMode: 'preview' | 'list') => {
    this.setState({ viewMode: newMode });
    localStorage.setItem(VIEW_MODE_STORAGE_KEY, newMode);
  };

  render() {
    const {
      viewMode,
      configuration,
      showAdvancedFilters,
      defaultFilters,
      filters,
      entityTypeOptions,
      poolOptions,
      statusFiltersOpen,
      reset,
      query,
    } = this.state;

    if (!configuration || !filters || !defaultFilters || !query) {
      return this.renderRequest();
    }

    const {
      selectedFlow,

      publishedAfter,
      publishedBefore,

      selectedEntityType,

      selectedPool,

      deleted,
      deletedLocally,
      existsLocally,

      selectedSourceSite,

      search,
    } = filters;

    if (!selectedFlow && !this.props.params.viewAll && !this.props.params.viewForSite) {
      return <Alert variant="light">This site is not configured to receive any content manually.</Alert>;
    }

    if (!entityTypeOptions?.length) {
      return <Alert variant="light">This Flow is not configured to receive any content.</Alert>;
    }

    if (!poolOptions?.length) {
      return <Alert variant="light">This site is not configured to receive content manually from any Pool.</Alert>;
    }

    // TODO: Add filter by source site.
    // TODO: When using the pager, scroll up. Drupal must provide a message for this that uses $('body').scrollTop(0).

    const bodyStyles = window.getComputedStyle(document.body);
    const primary = bodyStyles.getPropertyValue('--primary');
    const danger = bodyStyles.getPropertyValue('--danger');

    const filterCount =
      (selectedSourceSite ? 1 : 0) +
      (selectedEntityType ? 1 : 0) +
      (selectedPool ? 1 : 0) +
      (publishedAfter ? 1 : 0) +
      (publishedBefore ? 1 : 0);
    const defaultStatusFilter =
      deleted === defaultFilters.deleted &&
      deletedLocally === defaultFilters.deletedLocally &&
      existsLocally === defaultFilters.existsLocally;
    const restrictiveStatusFilter = deleted === true || deletedLocally === true || existsLocally === true;
    const hasAnyCustomFilters = filterCount > 0 || restrictiveStatusFilter || !!search;
    const hasAnyFilters = hasAnyCustomFilters || deleted !== undefined || deletedLocally !== undefined || existsLocally !== undefined;

    const isAppEmbed = !!this.props.params.viewAll;

    return (
      <div style={{ minHeight: '400px' }} className={isAppEmbed ? 'p-1' : 'pe-3 ps-3 pt-1'}>
        <ContractWarnings />

        <PagedList<ClientPreviewItem, IPreviewServiceFilters>
          numberOfItemsPerPageOptions={NUMBER_OF_ITEMS_PER_PAGE_OPTIONS}
          defaultNumberOfItemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
          highlightSearch=".paged-list .preview-html.has-preview, .paged-list .preview-name, .paged-list .preview-tag"
          searchable
          showLoadingAnimationImmediately
          isOnPageBackground
          key={reset?.toString()}
          showSpeed
          loadingAnimation={() => (
            <div className="m-3 mt-5 pt-5 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faSpinner} spin size="8x" className="text-light" />
            </div>
          )}
          initialFilters={{
            flowMachineName: selectedFlow?.value,
            entityTypeNamespaceMachineNames: selectedEntityType?.value.namespaceMachineName
              ? [selectedEntityType?.value.namespaceMachineName]
              : [],
            entityTypeMachineNames: selectedEntityType?.value.machineName ? [selectedEntityType?.value.machineName] : [],
            poolMachineNames: selectedPool?.value ? [selectedPool?.value] : [],
            publishedEarliest: publishedAfter,
            publishedLatest: publishedBefore,
            search,
            sourceSiteId: selectedSourceSite?.id,
            deleted,
            deletedLocally,
            existsLocally,
          }}
          request={(page, filter, itemsPerPage) => {
            setTimeout(() => {
              const { changed, query } = this.getQueryFromFilters(this.state.query!, this.state.filters!);
              if (changed) {
                sendToParent({
                  type: 'update-query',
                  query: instanceToPlain(query),
                });
              }
            }, 1);

            return this.api.syndication.previews.list(
              {
                flowMachineName: selectedFlow?.value,
                deleted,
                deletedLocally,
                existsLocally,
                ...filter,
              },
              { page, itemsPerPage: itemsPerPage || DEFAULT_NUMBER_OF_ITEMS_PER_PAGE }
            );
          }}
          renderFilters={(onChange) => {
            const flowFilter =
              configuration.flows.length > 1 && !this.isBackendEmbed ? (
                <Select
                  value={selectedFlow}
                  options={configuration.flows.map((c) => ({ label: c.name, value: c.machineName }))}
                  onChange={(selectedFlow) => {
                    if (selectedFlow && selectedFlow.value !== this.state.filters!.selectedFlow!.value) {
                      this.updateFilters(selectedFlow);
                      onChange('flowMachineName', selectedFlow.value, true);
                    }
                  }}
                  id="flow-filter"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '250px',
                    }),
                  }}
                  placeholder="Select Flow..."
                />
              ) : undefined;
            const typeFilter =
              entityTypeOptions.length > 0 ? (
                <Select<IEntityTypeOption>
                  value={selectedEntityType}
                  options={entityTypeOptions as IEntityTypeOption[]}
                  onChange={(selectedEntityType) => {
                    this.setState({ filters: { ...filters, selectedEntityType: selectedEntityType || undefined } });
                    onChange(
                      {
                        entityTypeNamespaceMachineNames: selectedEntityType ? [selectedEntityType.value.namespaceMachineName] : undefined,
                        entityTypeMachineNames: selectedEntityType ? [selectedEntityType.value.machineName] : undefined,
                      },
                      true
                    );
                  }}
                  //components={{ Option: CustomOption }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '200px',
                    }),
                  }}
                  isClearable
                  placeholder="Select type..."
                />
              ) : undefined;
            const poolFilter =
              poolOptions.length > 0 ? (
                <Select
                  value={selectedPool}
                  options={poolOptions}
                  onChange={(selectedPool) => {
                    this.setState({ filters: { ...filters, selectedPool: selectedPool || undefined } });
                    onChange('poolMachineNames', selectedPool ? [selectedPool.value] : undefined, true);
                  }}
                  //components={{ Option: CustomOption }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '200px',
                    }),
                  }}
                  isClearable
                  placeholder="Select Pool..."
                />
              ) : undefined;

            const now = moment();
            const isToday = (date: moment.Moment) => now.isSame(date, 'date');

            const statusFilter = (name: 'existsLocally' | 'deleted' | 'deletedLocally', label: string) => (
              <div className="mb-3">
                <div>
                  <Form.Label>{label}</Form.Label>
                </div>
                <div>
                  <ButtonGroup className="shadow-border">
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === true ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: true } } as any);
                        onChange(name, true, true);
                      }}
                      className={`${name + '-yes'} border-primary border-end`}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === false ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: false } } as any);
                        onChange(name, false, true);
                      }}
                      className={`${name + '-no'} border-primary border-end border-start`}
                    >
                      No
                    </Button>
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === undefined ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: undefined } } as any);
                        onChange(name, undefined, true);
                      }}
                      className={`${name + '-both'} border-primary border-start`}
                    >
                      Both
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );

            return (
              <>
                <div className="d-flex">
                  {flowFilter ? <div className="pe-1">{flowFilter}</div> : undefined}
                  <div className="px-1">
                    <Button
                      ref={this.statusFiltersButton}
                      variant={statusFiltersOpen ? 'light' : 'light'}
                      onClick={() => this.setState({ statusFiltersOpen: !statusFiltersOpen })}
                      id="status-filter"
                      className="shadow-sm"
                    >
                      Status: {defaultStatusFilter ? this.defaultStatusFilterName : <Badge bg="danger">custom</Badge>}{' '}
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                    <Overlay target={this.statusFiltersButton.current} show={statusFiltersOpen} placement="bottom">
                      {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                          }}
                          className={`bg-white rounded p-2 shadow ${props.className || ''}`}
                        >
                          {statusFilter('existsLocally', 'Exists here')}
                          {statusFilter('deleted', 'Source deleted')}
                          {statusFilter('deletedLocally', 'Deleted here')}

                          <Right>
                            <Button
                              className="me-3 py-1 px-2"
                              variant={defaultStatusFilter ? 'dark' : 'link'}
                              onClick={() => {
                                const setTo = { deleted: false, deletedLocally: false, existsLocally: false };
                                this.setState({ filters: { ...filters, ...setTo } });
                                onChange(setTo, true);
                              }}
                              id="status-filter-reset"
                            >
                              Reset
                            </Button>
                          </Right>
                        </div>
                      )}
                    </Overlay>
                  </div>
                  <div className={`${flowFilter ? 'px-1' : 'pe-1'} flex-grow-1 align-self-stretch position-relative`}>
                    <Form.Control
                      autoFocus
                      id="searchbar"
                      type="text"
                      placeholder="Search..."
                      value={search || ''}
                      onChange={(e) => {
                        const search = e.target.value || undefined;
                        this.setState({ filters: { ...filters, search } });
                        onChange('search', search);
                      }}
                    />
                    <div
                      className={`${search ? 'd-block' : 'd-none'} cursor-pointer px-2 op-hover`}
                      style={{ top: '5px', bottom: '5px', right: '10px', position: 'absolute' }}
                      onClick={() => {
                        this.setState({ filters: { ...filters, search: '' } });
                        onChange('search', '', true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="align-middle text-dark" />
                    </div>
                  </div>
                  <div className="ps-1">
                    <Button
                      variant={'light'}
                      onClick={() => this.setState({ showAdvancedFilters: !showAdvancedFilters })}
                      id="advanced-filter"
                      className="bg-none"
                    >
                      <FontAwesomeIcon icon={faFilter} /> Advanced
                      {filterCount ? (
                        <>
                          {' '}
                          <Badge bg={'danger'}>{filterCount}</Badge>
                        </>
                      ) : null}{' '}
                      <FontAwesomeIcon icon={showAdvancedFilters ? faChevronUp : faChevronDown} />
                    </Button>
                  </div>
                  <ViewModeToggle // Pass the setPreviewMode function to the child component
                    setPreviewMode={this.setPreviewMode}
                    // Pass the current previewMode to the child component
                    previewMode={viewMode || 'preview'}
                  />
                </div>
                <Collapse in={!!showAdvancedFilters}>
                  <div>
                    <div className="d-flex mt-3">
                      <div className="pe-1" style={{ width: '200px' }}>
                        <div>
                          <Form.Label>Source</Form.Label>
                        </div>
                        <SelectSite
                          selectedSite={selectedSourceSite}
                          onSelect={(selectedSourceSite) => {
                            onChange('sourceSiteId', selectedSourceSite?.id, true);
                            this.setState({ filters: { ...filters, selectedSourceSite } });
                          }}
                          isClearable
                        />
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Type</Form.Label>
                        </div>
                        {typeFilter}
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Pool</Form.Label>
                        </div>
                        {poolFilter}
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Published after</Form.Label>
                        </div>
                        <SingleDatePicker
                          date={publishedAfter || null}
                          onDateChange={(publishedAfter) => {
                            const value = publishedAfter?.startOf('day');
                            this.setState({ filters: { ...filters, publishedAfter: value || undefined } });
                            onChange('publishedEarliest', value?.valueOf() || undefined, true);
                          }}
                          isOutsideRange={() => false}
                          isDayHighlighted={isToday}
                          focused={this.state.publishedAfterFocused || false}
                          onFocusChange={({ focused: publishedAfterFocused }) => this.setState({ publishedAfterFocused })}
                          id="publishedAfter"
                          placeholder="Select date..."
                          showClearDate
                        />
                      </div>
                      <div className="ps-1">
                        <div>
                          <Form.Label>Published before</Form.Label>
                        </div>
                        <SingleDatePicker
                          date={publishedBefore || null}
                          onDateChange={(publishedBefore) => {
                            const value = publishedBefore?.endOf('day');
                            this.setState({ filters: { ...filters, publishedBefore: value || undefined } });
                            onChange('publishedLatest', value?.valueOf() || undefined, true);
                          }}
                          isOutsideRange={() => false}
                          isDayHighlighted={isToday}
                          focused={this.state.publishedBeforeFocused || false}
                          onFocusChange={({ focused: publishedBeforeFocused }) => this.setState({ publishedBeforeFocused })}
                          id="publishedBefore"
                          placeholder="Select date..."
                          showClearDate
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </>
            );
          }}
          emptyMessage={
            <Alert variant={'light'}>
              No content matches {hasAnyCustomFilters ? 'your' : 'the default'} filters.&nbsp;
              {hasAnyCustomFilters && (
                <Button
                  variant="link"
                  onClick={() =>
                    this.setState({
                      reset: Date.now(),
                      filters: {
                        selectedFlow,
                        ...defaultFilters,
                      },
                    })
                  }
                >
                  Reset
                </Button>
              )}
              {hasAnyFilters && (
                <>
                  {hasAnyCustomFilters && ' or '}
                  <Button
                    variant="link"
                    onClick={() =>
                      this.setState({
                        reset: Date.now(),
                        filters: {
                          selectedFlow,
                        },
                      })
                    }
                  >
                    Show everything
                  </Button>
                </>
              )}
            </Alert>
          }
          renderItem={(item, index, { setFilter }) => {
            return (
              <PullDashboardItem
                key={item.entity.id}
                item={item}
                pullWithFlowMachineName={selectedFlow?.value}
                configurationAccess={this.props.params.configurationAccess}
                imagePreviewJwt={this.props.params.imagePreviewJwt}
                addTextFilter={(search) => {
                  this.setState({ filters: { ...filters, search } });
                  setFilter('search', search, true);
                }}
                viewMode={this.state.viewMode}
              />
            );
          }}
        />
      </div>
    );
  }
}

export const PullDashboard = () => (
  <SyncCoreFeatureFlagGate
    featureName={FEATURE_PREVIEWS}
    ifEnabled={() => (
      <ParamsComponent<Params> Params={Params}>
        {(params: Params) => {
          return <PullDashboardWithParams params={params} />;
        }}
      </ParamsComponent>
    )}
    ifDisabled={() => (
      <Alert variant="warning">
        The Sync Core you are connected to doesn't support previews so the pull dashboard can't be used.
        <br />
        <br />
        Please contact your Sync Core owner to enable this feature if you want to use it.
      </Alert>
    )}
  />
);

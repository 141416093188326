import { IRuntimeReference } from '@edgebox/data-definition-kit';
import { ExternalLink } from '@edgebox/react-components';
import { IRuntimeSyndicationOperation, SyndicationOperationSubType, SyndicationOperationType } from '@edgebox/sync-core-data-definitions';
import { ClientFileEntity, ClientPoolEntity, ClientRemoteEntityRevisionEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { PoolSummaryIcon } from './PoolSummaryIcon';
import { OPERATION_DURATION_DANGER, OPERATION_DURATION_WARNING, formatDuration } from '../Helpers';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';

interface IProps {
  operation: IRuntimeSyndicationOperation;
}

interface IState extends ISyncCoreApiComponentState {
  entity?: ClientRemoteEntityRevisionEntity;
  file?: ClientFileEntity;
}

export function getOperationIndexAndName(index: number, operation: IRuntimeSyndicationOperation): string {
  const name = getOperationName(operation);
  if (name) {
    return `Operation ${index + 1}: ${name}`;
  }
  return `Operation ${index + 1}`;
}
export function getOperationName(operation: IRuntimeSyndicationOperation): string {
  if (operation.type === SyndicationOperationType.DeleteEntity) {
    return 'Delete';
  } else if (operation.type === SyndicationOperationType.PushEntity) {
    return 'Update';
  } else if (operation.type === SyndicationOperationType.PushEntityTranslation) {
    if (operation.isTranslationRoot) {
      return `Update root translation ${operation.language || ''}`;
    } else {
      return `Update translation ${operation.language || ''}`;
    }
  } else if (operation.type === SyndicationOperationType.RetrieveEntity) {
    return 'Request entity';
  } else if (operation.type === SyndicationOperationType.RetrieveEntityTranslation) {
    if (operation.cloneExisting) {
      return `Keep existing translation ${operation.language || ''}`;
    } else if (operation.isTranslationRoot) {
      return `Request root translation ${operation.language || ''}`;
    } else {
      return `Request translation ${operation.language || ''}`;
    }
  } else if (operation.type === SyndicationOperationType.DeleteEntityTranslation) {
    return `Delete translation ${operation.language || ''}`;
  } else if (operation.type === SyndicationOperationType.RetrieveEntityListInit) {
    return 'Count entities';
  } else if (operation.type === SyndicationOperationType.RetrieveEntityListPage) {
    return `List entities (page ${operation.page})`;
  } else if (operation.type === SyndicationOperationType.RetrieveFile) {
    return 'Download';
  } else if (operation.type === SyndicationOperationType.RetrieveConfig) {
    return 'Sync config';
  } else if (operation.type === SyndicationOperationType.Publish) {
    return 'Publish';
  } else if (operation.type === SyndicationOperationType.Recover) {
    if (operation.subType === SyndicationOperationSubType.RecoveryClearEntityCache) {
      return 'Clear entity cache';
    }
  }
  return '';
}

export class OperationName extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { operation } = this.props;

    let state: Partial<IState> = {};
    if (operation.entity) {
      state.entity = (await operation.entity.get()) as ClientRemoteEntityRevisionEntity;
    }
    if (operation.file) {
      state.file = (await operation.file.get()) as ClientFileEntity;
    }

    return state;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.props.operation.entity?.getId() !== prevProps.operation.entity?.getId()) {
      this.load();
    }
  }

  render() {
    const { operation } = this.props;
    const { entity, file } = this.state;

    let action: React.ReactNode;
    let link: string | undefined = undefined;
    if (operation.type === SyndicationOperationType.DeleteEntity) {
      action = <strong>Delete</strong>;
    } else if (operation.type === SyndicationOperationType.PushEntity) {
      action = <strong>Update</strong>;
      link = entity?.viewUrl;
    } else if (operation.type === SyndicationOperationType.PushEntityTranslation) {
      if (operation.isTranslationRoot) {
        action = (
          <>
            <strong>Update</strong> root translation <strong>{operation.language || undefined}</strong>
          </>
        );
      } else {
        action = (
          <>
            <strong>Update</strong> translation <strong>{operation.language || undefined}</strong>
          </>
        );
      }
      link = entity?.viewUrl;
    } else if (operation.type === SyndicationOperationType.RetrieveEntity) {
      action = <strong>Request entity</strong>;
      link = entity?.viewUrl;
    } else if (operation.type === SyndicationOperationType.RetrieveEntityTranslation) {
      if (operation.cloneExisting) {
        action = (
          <>
            Keep existing translation <strong>{operation.language || undefined}</strong>
          </>
        );
      } else if (operation.isTranslationRoot) {
        action = (
          <>
            Request root translation <strong>{operation.language || undefined}</strong>
          </>
        );
      } else {
        action = (
          <>
            Request translation <strong>{operation.language || undefined}</strong>
          </>
        );
      }
      link = entity?.viewUrl;
    } else if (operation.type === SyndicationOperationType.DeleteEntityTranslation) {
      action = (
        <>
          Delete translation <strong>{operation.language || undefined}</strong>
        </>
      );
    } else if (operation.type === SyndicationOperationType.RetrieveEntityListInit) {
      action = 'Count entities';
    } else if (operation.type === SyndicationOperationType.RetrieveEntityListPage) {
      action = `List entities (page ${operation.page})`;
    } else if (operation.type === SyndicationOperationType.RetrieveFile) {
      action = 'Download';
      link = file?.downloadUrl;
    } else if (operation.type === SyndicationOperationType.RetrieveConfig) {
      action = 'Sync config';
    } else if (operation.type === SyndicationOperationType.Publish) {
      action = 'Publish';
    } else if (operation.type === SyndicationOperationType.Recover) {
      if (operation.subType === SyndicationOperationSubType.RecoveryClearEntityCache) {
        action = 'Clear entity cache';
      }
    }

    action = (
      <>
        {operation.pools?.length &&
        [SyndicationOperationType.RetrieveEntity, SyndicationOperationType.RetrieveEntityTranslation].includes(operation.type) ? (
          <PoolSummaryIcon poolReferences={operation.pools as IRuntimeReference<ClientPoolEntity>[]} />
        ) : undefined}
        {action}
      </>
    );

    let name: React.ReactNode;

    if (entity) {
      name = entity.name || 'entity';
    } else if (file) {
      name = file.fileName || 'file';
    } else if (
      [
        SyndicationOperationType.DeleteEntity,
        SyndicationOperationType.PushEntity,
        SyndicationOperationType.PushEntityTranslation,
        SyndicationOperationType.RetrieveFile,
      ].includes(operation.type)
    ) {
      name = <FontAwesomeIcon icon={faSpinner} spin className={`text-dark`} />;
    }

    if (link) {
      name = (
        <>
          from source <ExternalLinkWithIcon to={link}>{name}</ExternalLinkWithIcon>
        </>
      );
    }

    const duration =
      typeof operation.requestDuration === 'number' ? (
        <span className={'text-muted'}>
          in{' '}
          <span
            className={
              operation.requestDuration > OPERATION_DURATION_DANGER
                ? 'text-danger'
                : operation.requestDuration > OPERATION_DURATION_WARNING
                  ? 'text-warning'
                  : 'text-muted'
            }
          >
            {formatDuration(operation.requestDuration)}
          </span>
        </span>
      ) : null;

    return (
      <>
        {action} {name} {duration}
      </>
    );
  }
}

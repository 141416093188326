import { Right } from '@edgebox/react-components';
import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { EmbeddedModal } from '../../../EmbeddedModal';
import { MigrateFlow } from './MigrateFlow';
import { FlowMigrationStatus, MigrateParams } from './MigrateParams';

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  flows?: FlowMigrationStatus[];
  submitting?: boolean;
  wantsToSkip?: boolean;
  confirmed?: boolean;
}

export class MigratePull extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const params = this.props.params;
    const flows = params.flows.filter((c) => !!c.types.find((c) => !!c.pullMode));
    flows.forEach((c) =>
      c.types.sort((a, b) => {
        return a.namespaceMachineName > b.namespaceMachineName
          ? 1
          : a.namespaceMachineName < b.namespaceMachineName
            ? -1
            : a.machineName > b.machineName
              ? 1
              : -1;
      })
    );

    return {
      flows,
    };
  }

  render() {
    const { params } = this.props;
    const { flows, submitting, wantsToSkip, confirmed } = this.state;

    if (!flows) {
      return this.renderRequest();
    }

    if (!flows.length) {
      return <Alert variant="dark">You have not configured any Flow to pull content.</Alert>;
    }

    const allSkipped = !flows.find((c) => !c.skipPull);

    return (
      <>
        <Alert variant="warning">
          Before you map existing entities, you need to first push all existing content to the Sync Core. So first double check that you
          have pushed all content on all other sites to the new Sync Core and only then continue.
          <div className="mt-3 bg-white rounded p-2 ps-3">
            <Form.Check
              id="pull-confirmation"
              type="checkbox"
              checked={!!confirmed}
              onChange={() => this.setState({ confirmed: !confirmed })}
              label={
                <span className="cursor-pointer fw-bold text-black">
                  I have pushed all existing content from other sites to the new Sync Core
                </span>
              }
            />
          </div>
        </Alert>
        {confirmed && flows.map((flow) => <MigrateFlow key={flow.machineName} flow={flow} params={params} push={false} />)}
        {!allSkipped && (
          <Right className="m-0 mt-3">
            <Button variant="danger" onClick={() => this.setState({ wantsToSkip: true })} disabled={submitting || wantsToSkip}>
              Skip all Flows
            </Button>
          </Right>
        )}
        {wantsToSkip && (
          <EmbeddedModal show={true} onHide={() => this.setState({ wantsToSkip: undefined })} size="xl" scrollable>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This will mark all Flows with all their entity types as <em>skipped</em> so you can continue to the next migration step.
              <br />
              <br />
              You can still come back later and continue mapping.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                className="fw-bold"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    submitting: true,
                  });

                  sendToParent({
                    type: 'migration-skip-flows-pull',
                    machineNames: flows.map((c) => c.machineName),
                  });
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </EmbeddedModal>
        )}
      </>
    );
  }
}

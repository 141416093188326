import { ExternalServiceId, InternalId } from '@edgebox/data-definition-kit';
import { getStyleColors } from '../../../../../Helpers';
import { ButtonLink, LeftRightSpan } from '@edgebox/react-components';
import { EntityRemoteStatus, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import { ClientPreviewItem, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Accordion, Badge, Button, ButtonGroup, Dropdown, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../../services/SyncCoreApiComponent';
import { EmbeddedModal } from '../../../../EmbeddedModal';
import { PoolSummaryIcon } from '../../../../PoolSummaryIcon';
import { SerializedEntityRevision } from '../../../../SerializedEntityRevision';
import { SiteName } from '../../../../SiteName';
import { doneStatus, SyndicationStatusIcon } from '../../../SyndicationStatusIcon';
import { SwitchButton } from '../../../../SwitchButton';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { modalClose, modalOpen } from '../../../../../frame-messages';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { Nugget } from '../../../../Nugget';
import { getEntityTypeIcon } from '../../../EntityTypeIcon';
import { FormatDate } from '../../../../FormatDate';
import { ExternalLinkWithIcon } from '../../../../ExternalLinkWithIcon';
import { ICON_PULL } from '../../../../../Helpers';
import { CustomOption, EntityStatusWithParams } from '../../EntityStatus';
import { DisplayEntityRevision } from '../../../../DisplayEntityRevision';
import { WithSite } from '../../../../WithSite';
import HasMultiplePools from './HasMultiplePools';
import ItemPreview from './ItemPreview';
import WithMostRecentSyndications from './WithMostRecentSyndications';

// Start with refreshing every second.
const REFRESH_INTERVAL_RUNNING = 1_000;
// Double the refresh interval every 4 times.
const REFRESH_INTERVAL_DOUBLE_TIME = 4;
const REFRESH_INTERVAL_ISSUE = 20_000;

interface IItemProps {
  item: ClientPreviewItem;
  pullWithFlowMachineName?: ExternalServiceId;
  configurationAccess?: boolean;
  imagePreviewJwt?: string;
  addTextFilter?: (text: string) => void;
  viewMode?: 'list' | 'preview';
}
interface IItemState extends ISyncCoreApiComponentState {
  refreshCount?: number;
  item?: ClientPreviewItem;
  syndication?: ClientSyndicationEntity;
  pullingEntity?: boolean;
  serialize?: boolean;
  showUpdate?: boolean;
  serializeMode?: 'table' | 'yaml-pretty' | 'yaml-raw';
  selectedComparison?: number;
  selectedComparisonId?: InternalId;
}

export class PullDashboardItem extends SyncCoreApiComponent<IItemProps, IItemState> {
  async load(stateUpdate?: Pick<IItemState, 'pullingEntity' | 'syndication'>) {
    const state = { ...this.state, ...(stateUpdate || {}) };
    const item = state.item || this.props.item;
    let syndication = state.syndication || item.lastPull;
    const status = syndication?.status;
    const { refreshCount } = state;

    if (refreshCount) {
      syndication = await this.api.syndication.syndications.item(syndication!.id, true, { includeUsage: true });
    }

    let interval: number;
    if (!status || doneStatus.includes(status)) {
      interval = 0;
    } else if (status === SyndicationStatus.Initializing || status === SyndicationStatus.Running) {
      interval = REFRESH_INTERVAL_RUNNING;
      const doubleTimes = Math.floor(refreshCount || 0 / REFRESH_INTERVAL_DOUBLE_TIME);
      interval *= Math.pow(2, doubleTimes);
    } else {
      interval = REFRESH_INTERVAL_ISSUE;
    }

    if (interval > 0) {
      setTimeout(() => {
        if (!this.__isMounted) {
          return;
        }
        this.load();
      }, interval);
    }

    return {
      ...(stateUpdate || {}),
      refreshCount: refreshCount !== undefined ? refreshCount + 1 : 0,
      syndication,
    };
  }

  render() {
    const item = this.state.item || this.props.item;
    const syndication = this.state.syndication || item.lastPull;
    const { pullWithFlowMachineName, imagePreviewJwt, viewMode } = this.props;
    const { pullingEntity, showUpdate, serialize, serializeMode, selectedComparison, selectedComparisonId } = this.state;

    const wasDeleted = item.localUsage && item.localUsage.status !== EntityRemoteStatus.Exists;
    const deleted = wasDeleted && (!this.state.syndication || this.state.syndication === this.props.item.lastPull);
    const sourceDeleted = item.sourceUsage.status !== EntityRemoteStatus.Exists;
    let viewUrl: string | undefined = undefined;
    if (!pullingEntity) {
      if (syndication) {
        if (!wasDeleted || syndication.status === SyndicationStatus.Finished) {
          viewUrl = syndication.usage?.viewUrl || item.localUsage?.viewUrl;
        }
      } else if (!wasDeleted) {
        viewUrl = item.localUsage?.viewUrl;
      }
    }

    const canPull = !!pullWithFlowMachineName;
    const exists = viewUrl && !deleted;
    const showPull = !syndication || doneStatus.includes(syndication.status);
    const actionDropDown = true;

    const viewSource = (secondary: boolean, embedded = true) =>
      item.sourceUsage.status === EntityRemoteStatus.Exists ? (
        <ExternalLinkWithIcon
          to={item.sourceUsage.viewUrl}
          className={
            embedded ? `${secondary ? `p-2 ${viewUrl ? 'text-muted' : ''}` : `px-1`}` : 'text-decoration-none fs-5 d-block px-3 py-2'
          }
        >
          View source
        </ExternalLinkWithIcon>
      ) : embedded ? (
        <Badge bg="warning" className="ms-1">
          Source deleted
        </Badge>
      ) : null;

    let actions: React.ReactNode[] = [];
    if (!exists || item.sourceUsage.viewUrl != item.localUsage?.viewUrl) {
      actions.push(viewSource(true, !actionDropDown));
    }

    if (this.props.configurationAccess) {
      actions.push(
        <Button
          variant="link"
          className={`text-muted ${actionDropDown ? 'px-3 py-2 d-block w-100 text-start text-decoration-none fs-5' : 'px-2'}`}
          onClick={() => {
            this.setState({ serialize: true, selectedComparisonId: undefined });
            modalOpen();
          }}
        >
          Serialize
        </Button>
      );
      actions.push(
        <Button
          variant="link"
          className={`text-muted ${actionDropDown ? 'px-3 py-2 d-block w-100 text-start text-decoration-none fs-5' : 'px-2'}`}
          onClick={() => this.setState({ showUpdate: true })}
        >
          Show Updates
        </Button>
      );
    }

    const menuItems = (
      <Dropdown.Menu style={{ width: '200px', zIndex: 99999 }} className="shadow-sm">
        {actions
          .filter((c) => !!c)
          .map((c, index) => (
            <Dropdown.Item className="p-0" key={index} as={'div'}>
              {c}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    );

    if (exists) {
      actions = [
        <>
          <Dropdown as={ButtonGroup} className="rounded bg-white-selectable shadow-sm" onClick={(e) => e.stopPropagation()}>
            <ButtonLink
              to={viewUrl || ''}
              variant="light"
              className="pull-submit ps-3 pe-3 pt-2 pb-2 fs-5 fw-bold bg-none color-dark"
              disabled={!viewUrl}
              target="_blank"
              style={{ width: '172px' }}
            >
              {canPull ? 'View here' : 'View'} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </ButtonLink>

            <Dropdown.Toggle split variant="light" id="dropdown-split-basic" className="border-start bg-none color-dark" />

            {menuItems}
          </Dropdown>
        </>,
      ];
    } else if (canPull) {
      actions = [
        <>
          <Dropdown as={ButtonGroup} className="rounded bg-primary-selectable shadow-sm" onClick={(e) => e.stopPropagation()}>
            <Button
              variant="primary"
              className="pull-submit px-3 pt-2 pb-2 fs-5 fw-bold bg-none"
              disabled={!!pullingEntity || !showPull}
              style={{ width: '172px' }}
              onClick={(e) => {
                e.preventDefault();

                this.setState({ pullingEntity: true });

                (async () => {
                  try {
                    const syndication = await this.api.syndication.syndications.pull({
                      flowMachineName: pullWithFlowMachineName,
                      entityTypeMachineName: item.entityTypeVersion.machineName,
                      entityTypeNamespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                      manually: true,
                      remoteUuid: item.entity.remoteUuid,
                      remoteUniqueId: item.entity.remoteUniqueId,
                    });

                    const stateUpdate = {
                      pullingEntity: undefined,
                      syndication,
                    };

                    await this.load(stateUpdate);
                  } catch (e) {
                    this.setState({
                      pullingEntity: undefined,
                    });

                    if ((e as Error).message) toast((e as Error).message, { type: 'error' });
                  }
                })();

                return false;
              }}
            >
              {pullingEntity ? <FontAwesomeIcon icon={faSpinner} spin /> : undefined}
              {deleted ? (
                'Restore'
              ) : (
                <>
                  {syndication ? (
                    <SyndicationStatusIcon
                      status={syndication.status}
                      errorType={syndication.operations?.[0]?.errors?.[0]?.type}
                      size="sm"
                      background="primary"
                    />
                  ) : pullingEntity ? null : (
                    <FontAwesomeIcon icon={ICON_PULL} className="me-2" />
                  )}{' '}
                  Pull
                </>
              )}
            </Button>

            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="border-start bg-none" />

            {menuItems}
          </Dropdown>
        </>,
      ];
    }

    const { primary, danger } = getStyleColors();

    const nuggets = (
      <div className={`${viewMode === 'list' ? '' : 'ps-3 '} pt-3 fs-7 mb-3 nuggets`}>
        <Nugget icon={ICON_PULL}>
          <SiteName
            id={item.sourceUsage.site.getId()}
            link={sourceDeleted ? undefined : item.sourceUsage.viewUrl}
            linkIconClassName="ms-1"
            className="text-muted"
            inline
          />
        </Nugget>
        <HasMultiplePools>
          {item.entity.pools?.length ? <PoolSummaryIcon poolReferences={item.entity.pools} wide className="text-muted" nugget /> : <></>}
        </HasMultiplePools>
        <Nugget
          icon={
            getEntityTypeIcon(item.entity.appType, item.entityTypeVersion.namespaceMachineName, item.entityTypeVersion.machineName).icon
          }
        >
          {item.entityTypeVersion.name}
        </Nugget>
        <Nugget icon={faClock}>
          <FormatDate withTime>{item.entity.createdAt}</FormatDate>
        </Nugget>
      </div>
    );

    const headingSharedComponents = (
      <>
        <div className="preview-name inline-block flex-shrink-1 align-self-center text-truncate" title={item.entity.name}>
          {pullWithFlowMachineName ? (
            deleted ? (
              <FontAwesomeIcon icon={faTrashCan} className="text-danger me-2 fs-4" />
            ) : viewUrl ? (
              <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2 fs-4" />
            ) : undefined
          ) : undefined}
          {item.entity.name || <em>Unnamed</em>}{' '}
        </div>
        {(!pullWithFlowMachineName || !deleted) && item.sourceUsage.createdAt.isAfter(moment().subtract(1, 'week')) && (
          <div className="flex-grow-0 align-self-center flex-shrink-0 ms-3 fs-4 me-3" style={{ marginLeft: '1rem !important' }}>
            <Badge bg="danger">New</Badge>
          </div>
        )}
      </>
    );

    const actionComponents = (
      <div>
        <div className="text-end pt-2 pb-3 pe-3">
          {actions.map((c, i) => (
            <React.Fragment key={i}>{c}</React.Fragment>
          ))}
        </div>
      </div>
    );

    return (
      <>
        {viewMode === 'list' && (
          <Accordion defaultActiveKey="" className="mb-3 embed-pd">
            <Accordion.Item eventKey={item.entity.id}>
              <Accordion.Header className="">
                <div key={item.entity.id} className="preview-item d-flex align-items-center w-100">
                  <div className="flex-grow-1 d-flex flex-column align-items-start ps-3 pt-2 overflow-hidden">
                    <h2 className="mb-0 d-inline-flex justify-content-start w-100">{headingSharedComponents}</h2>
                    {nuggets}
                  </div>

                  <div className="ms-auto d-flex f-flex flex-shrink-0 ps-2 align-items-center">
                    <div className="f-flex flex-shrink-0 flex-grow-0 me-4">
                      {actions.map((c, i) => (
                        <React.Fragment key={i}>{c}</React.Fragment>
                      ))}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="flex-shrink-0 flex-grow-0 me-4 fs-4" />
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ItemPreview item={item} addTextFilter={this.props.addTextFilter} imagePreviewJwt={imagePreviewJwt} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}

        {viewMode === 'preview' && (
          <div key={item.entity.id} className="preview-item rounded shadow-sm my-4 bg-white">
            <h2 className="mb-0 d-inline-flex justify-content-start ps-3 pt-2 w-100">{headingSharedComponents}</h2>
            {nuggets}
            <ItemPreview item={item} addTextFilter={this.props.addTextFilter} imagePreviewJwt={imagePreviewJwt} />
            {actionComponents}
          </div>
        )}

        {serialize && (
          <EmbeddedModal
            scrollable
            size="lg"
            fullscreen
            show
            onHide={() => {
              this.setState({ serialize: false });
              modalClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="w-100" title={item.entity.name}>
                <LeftRightSpan
                  className="w-100"
                  left={<span className="text-truncate mw-100">{item.entity.name || <em>Unnamed</em>}</span>}
                  right={
                    <div className="d-flex">
                      <div>
                        <SwitchButton
                          className="me-2"
                          selected={serializeMode ?? 'table'}
                          options={{ table: 'Table', 'yaml-pretty': 'Technical', 'yaml-raw': 'Raw' }}
                          size="m"
                          onSelect={async (newValue) => {
                            this.setState({ serializeMode: newValue });
                          }}
                        />
                      </div>
                      <div>
                        <WithSite entityId={item.sourceUsage.site.getId()}>
                          {(site) =>
                            site ? (
                              <WithMostRecentSyndications
                                siteUuid={site.uuid}
                                type={{
                                  namespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                                  machineName: item.entityTypeVersion.machineName,
                                }}
                                entity={item.entity}
                              >
                                {(recent) => (
                                  <Select
                                    isClearable
                                    value={selectedComparison === undefined ? undefined : recent[selectedComparison]}
                                    getOptionValue={(option) => (option.sourceSite ?? option.thisSite ?? option.thisSite).id}
                                    getOptionLabel={(option) => option.startedAt.fromNow()}
                                    isOptionDisabled={(option) =>
                                      !(option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() ||
                                      (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() === item.entity.id
                                    }
                                    placeholder="Compare to ..."
                                    options={recent}
                                    onChange={(option) =>
                                      this.setState({
                                        selectedComparison: !option ? undefined : recent.indexOf(option),
                                        selectedComparisonId: !option
                                          ? undefined
                                          : (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId(),
                                      })
                                    }
                                    components={{ Option: CustomOption }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary,
                                        danger,
                                      },
                                    })}
                                  />
                                )}
                              </WithMostRecentSyndications>
                            ) : null
                          }
                        </WithSite>
                      </div>
                    </div>
                  }
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!serializeMode || serializeMode === 'table' ? (
                <DisplayEntityRevision
                  id={item.entity.id}
                  comparisonId={selectedComparisonId}
                  key={item.entity.id + '-' + selectedComparisonId}
                />
              ) : (
                <SerializedEntityRevision
                  id={item.entity.id}
                  comparisonId={selectedComparisonId}
                  structure={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
                  values={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
                />
              )}
            </Modal.Body>
          </EmbeddedModal>
        )}

        {showUpdate && (
          <EmbeddedModal scrollable size="xl" show onHide={() => this.setState({ showUpdate: false })}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100" title={item.entity.name}>
                <span className="text-truncate mw-100">{item.entity.name || <em>Unnamed</em>}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EntityStatusWithParams
                embedded
                params={{
                  configurationAccess: true,
                  namespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                  machineName: item.entityTypeVersion.machineName,
                  remoteUniqueId: item.entity.remoteUniqueId,
                  remoteUuid: item.entity.remoteUuid,
                }}
              />
            </Modal.Body>
          </EmbeddedModal>
        )}
      </>
    );
  }
}

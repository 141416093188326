import { ContentCol, HeaderCol } from '@edgebox/react-components';
import { TransformFnParams, TransformationType, plainToInstance } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';
import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import { AppParamsContext, IAppParams } from '../AppParams';
import moment from 'moment';

export function transformBool(params: TransformFnParams) {
  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    return params.value === true || params.value === 'true' ? 'true' : params.value === false || params.value === 'false' ? 'false' : '';
  } else if (params.type === TransformationType.PLAIN_TO_CLASS) {
    return params.value === 'true' || params.value === true ? true : params.value === 'false' || params.value === false ? false : undefined;
  }
  return params.value;
}

export function transformInteger(params: TransformFnParams, positive?: boolean) {
  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    return typeof params.value === 'number' ? params.value.toString() : typeof params.value === 'string' ? params.value : '';
  } else if (params.type === TransformationType.PLAIN_TO_CLASS) {
    const value = typeof params.value === 'string' ? parseInt(params.value) : params.value;
    return isNaN(value) || !isFinite(value) || typeof value !== 'number' || (positive && value < 0) ? undefined : value;
  }
  return params.value;
}

export function transformDate(params: TransformFnParams) {
  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    if (moment.isMoment(params.value)) {
      return params.value.valueOf();
    }
    if (typeof params.value === 'number') {
      return params.value.toString();
    }
    if (typeof params.value === 'string') {
      return params.value;
    }
    return undefined;
  } else if (params.type === TransformationType.PLAIN_TO_CLASS) {
    if (moment.isMoment(params.value)) {
      return params.value;
    }
    if (typeof params.value === 'number') {
      return moment(params.value);
    }
    if (typeof params.value === 'string') {
      return moment(parseInt(params.value));
    }
    return undefined;
  }
  return params.value;
}

interface IProps<Params extends {}> {
  Params: { new (): Params };
  children: (params: Params) => React.ReactNode;
}

export interface IState<Params extends {}> {
  paramsRaw?: any;
  params?: Params;
  paramErrors?: ValidationError[];
}

export class ParamsComponent<Params extends {}> extends React.Component<IProps<Params>, IState<Params>> {
  constructor(props: IProps<Params>, state?: Partial<IState<Params>>) {
    super(props);

    this.state = (state || {}) as IState<Params>;
  }

  context!: IAppParams;
  static contextType = AppParamsContext;

  init() {
    const paramsRaw = this.context.pageOptions;
    const params = plainToInstance<Params, any>(this.props.Params, paramsRaw) as any as Params;
    const paramErrors = validateSync(params);

    //console.log(paramsRaw, params, paramErrors);

    this.setState({
      paramsRaw,
      params,
      paramErrors,
    });
  }

  componentDidMount() {
    if (this.context) {
      this.init();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps<Params>>, prevState: Readonly<IState<Params>>, snapshot?: any) {}

  renderErrors(paramErrors: ValidationError[]) {
    const renderSingleError = (e: ValidationError, index: number) => (
      <li key={index}>
        {e.property}:
        <ul>
          {e.constraints ? (
            Object.keys(e.constraints || {}).map((key, index) => <li key={index}>{e.constraints![key]}</li>)
          ) : (
            <ul>{e.children?.map(renderSingleError)}</ul>
          )}
        </ul>
      </li>
    );

    return (
      <Alert variant={'danger'}>
        <p className={'fw-bold'}>
          We are sorry, there's an issue loading this component. Please provide the URL and the following technical information to your
          support.
        </p>
        <ul>{paramErrors.map(renderSingleError)}</ul>
      </Alert>
    );
  }

  renderDebug() {
    const { params, paramsRaw } = this.state;

    if (!params || !paramsRaw) {
      return null;
    }

    const paramKeys = Object.keys(params as any);
    const debug = paramsRaw.debug === 'yes' || process.env.REACT_APP_DEBUG === 'yes';

    return debug ? (
      <>
        <h3>Params (debug)</h3>
        {paramKeys.length ? (
          paramKeys.map((k) => (
            <Row key={k}>
              <HeaderCol xs={2}>{k}</HeaderCol>
              <ContentCol xs={9}>{JSON.stringify(paramsRaw[k])}</ContentCol>
              <ContentCol xs={1}>{typeof (params as any)[k]}</ContentCol>
            </Row>
          ))
        ) : (
          <Alert variant={'light'}>None.</Alert>
        )}
        <h3>Content</h3>
      </>
    ) : null;
  }

  render() {
    const { params, paramErrors } = this.state;

    if (!params) {
      return null;
    }

    return (
      <>
        {this.renderDebug()}

        {paramErrors?.length ? this.renderErrors(paramErrors!) : this.props.children(params as Params)}
      </>
    );
  }
}

import { findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core';
import { InternalId } from '@edgebox/data-definition-kit';
import { ClientPreviewItem } from '@edgebox/sync-core-rest-client';
import React, { useContext } from 'react';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';
import { UserHtml } from '../../../../UserHtml';
import { makeNugget } from '../../../../Nugget';

const DISPLAY_TERMS_LIMIT = 5;

function ItemPreview({
  item,
  addTextFilter,
  imagePreviewJwt,
}: {
  item: ClientPreviewItem;
  addTextFilter?: (text: string) => void;
  imagePreviewJwt?: string;
}) {
  const api = useContext(SyncCoreApiContext);

  return item.previewHtml ? (
    <div className={`p-3 preview-html has-preview`}>
      <UserHtml
        dangerousHtml={item.previewHtml}
        renderer={{
          a: (element) => {
            element.setAttribute('target', '_blank');
          },
          // Move tags to our own tags section beneath the headline.
          '.pi-tags': (element) => {
            let parent = element.parentElement;
            while (parent && !parent.classList.contains('preview-item') && parent.parentElement && parent.parentElement !== document.body) {
              parent = parent.parentElement;
            }
            if (parent && parent.classList.contains('preview-item')) {
              const nuggets = parent.querySelector('.nuggets');
              if (nuggets) {
                element.classList.add('d-none');

                const tags = element.querySelectorAll('.pi-tag');
                tags.forEach((term, index) => {
                  const clb = addTextFilter;
                  const text = term.textContent;
                  makeNugget(
                    term,
                    'tag',
                    clb && text
                      ? () => {
                          clb(text);
                        }
                      : undefined
                  );

                  term.classList.add('preview-tag');

                  nuggets.appendChild(term);

                  if (index >= DISPLAY_TERMS_LIMIT) {
                    term.classList.add('d-none');
                  }

                  if (index === DISPLAY_TERMS_LIMIT) {
                    const more = document.createElement('span');
                    more.setAttribute('class', 'pi-tag');
                    more.innerText = '+' + (tags.length - DISPLAY_TERMS_LIMIT);
                    makeNugget(more, 'tag', () => {
                      nuggets.querySelectorAll('.d-none').forEach((c) => c.classList.remove('d-none'));
                      more.classList.add('d-none');
                    });

                    nuggets.appendChild(more);
                  }
                });
              }
            }
          },
          // Replace file references with actual HTML images.
          '.pi-image': (element) => {
            const fileId = element.getAttribute('data-file-id') as InternalId;
            let uri = element.getAttribute('data-file-uri');
            let alt = element.getAttribute('data-file-name');
            if (!fileId && !uri) {
              return;
            }

            const throbber = document.createElement('span');

            const iconDefinition = findIconDefinition({ prefix: 'fas', iconName: 'spinner' });
            const iconInstance = icon(iconDefinition);
            if (iconInstance) {
              Array.from(iconInstance.node).map((n) => {
                n.classList.add('fa-spin');
                throbber.appendChild(n);
              });
            }

            const container = document.createElement('div');
            if (element.parentElement?.classList.contains('pi-variant-image-text')) {
              container.setAttribute('class', 'float-start pe-3 pb-3');
              element.parentElement.classList.add('clearfix');
            }
            container.appendChild(throbber);

            function setImage() {
              if (uri) {
                container.classList.add('mw-25');

                // Max width, rounded up to the nearest 100s, to make it cacheable.
                const MAX_WIDTH = Math.ceil(((window.innerWidth || 1_000) * (window.devicePixelRatio || 1) * 0.25) / 100) * 100;
                // Design doesn't allow more than 300 CSS pixels, so we take that and round it to the nearest 100s, to make it cacheable.
                const MAX_HEIGHT = Math.ceil((300 * window.devicePixelRatio) / 100) * 100;

                const image = document.createElement('img');
                image.setAttribute(
                  'src',
                  uri
                    .replace(/(\[|%5B)JWT(\]|%5D)/g, encodeURIComponent(imagePreviewJwt || ''))
                    .replace(/(\[|%5B)MAX-WIDTH(\]|%5D)/g, MAX_WIDTH.toString())
                    .replace(/(\[|%5B)MAX-HEIGHT(\]|%5D)/g, MAX_HEIGHT.toString())
                );
                image.setAttribute('alt', alt || uri);
                image.setAttribute('loading', 'lazy');
                throbber.replaceWith(image);
              } else {
                throbber.replaceWith('');
              }
            }

            if (uri) {
              setImage();
            } else if (fileId) {
              // TODO: Load optimized, smaller images.
              // TODO: Only load images at all when in the viewport.
              function loadFile() {
                if (api) {
                  api.api.syndication.files.item(fileId!).then((file) => {
                    if (file.downloadUrl) {
                      uri = file.downloadUrl;
                      alt = file.fileName;
                    }
                    setImage();
                  });
                } else {
                  setTimeout(loadFile, 500);
                }
              }
              loadFile();
            } else {
              setImage();
            }

            element.replaceWith(container);
          },
        }}
      />
    </div>
  ) : null;
}

export default ItemPreview;

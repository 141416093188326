import { ExternalServiceId, InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  poolMachineNames?: ExternalServiceId[];
  poolIds?: InternalId[];
  namespaceMachineName?: ExternalServiceId;
  machineName?: ExternalServiceId;
}
interface IState extends ISyncCoreApiComponentState {
  count?: number;
}

export class CountSyncCoreEntities extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { namespaceMachineName, machineName, poolIds } = this.props;

    const poolMachineNames = this.props.poolMachineNames
      ? this.props.poolMachineNames
      : poolIds
        ? (await Promise.all(poolIds.map((c) => this.api.syndication.pools.item(c)))).map((c) => c.machineName)
        : undefined;

    const response = await this.api.syndication.remoteEntityRevisions.listLatest(
      {
        entityTypeNamespaceMachineName: namespaceMachineName,
        entityTypeMachineName: machineName,
        poolMachineNames,
      },
      {
        itemsPerPage: 0,
      }
    );

    return {
      count: response.totalNumberOfItems,
    };
  }

  render() {
    const { count } = this.state;

    if (count === undefined) {
      return <em>loading</em>;
    }

    return count;
  }
}

import React, { useContext, useEffect, useState } from 'react';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';

let hasMultiplePoolsLoading: Promise<boolean> | undefined = undefined;

function HasMultiplePools(props: { children: React.ReactElement }) {
  const api = useContext(SyncCoreApiContext);

  const [hasMultiplePools, setHasMultiplePools] = useState<boolean | undefined>();

  useEffect(() => {
    if (hasMultiplePoolsLoading) {
      hasMultiplePoolsLoading.then((has) => setHasMultiplePools(has));
    } else if (api) {
      hasMultiplePoolsLoading = (async () => {
        const page = await api.api.syndication.pools.list();
        const has = page.length > 1;
        setHasMultiplePools(has);
        return has;
      })();
    }
  }, [api]);

  if (hasMultiplePools === undefined || hasMultiplePools === false) {
    return <></>;
  }

  return props.children;
}

export default HasMultiplePools;

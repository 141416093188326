import { ClientSyndicationUsageSummary } from '@edgebox/sync-core-rest-client';
import { ExternalServiceId, Uuid } from '@edgebox/data-definition-kit';
import React, { useState } from 'react';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';

function WithMostRecentSyndications({
  children,
  siteUuid,
  entity,
  type,
}: {
  children: (recent: ClientSyndicationUsageSummary[]) => React.ReactElement;
  siteUuid: Uuid;
  type: { namespaceMachineName: ExternalServiceId; machineName: ExternalServiceId };
  entity: { remoteUniqueId?: ExternalServiceId; remoteUuid?: Uuid };
}) {
  const [recent, setRecent] = useState<ClientSyndicationUsageSummary[] | undefined>(undefined);

  if (recent) {
    return children(recent);
  }

  return (
    <SyncCoreApiContext.Consumer>
      {(api) => {
        if (!api) {
          return null;
        }

        api.api.syndication.syndications
          .usageSummaryForSite(
            siteUuid,
            type,
            entity.remoteUniqueId ? { remoteUniqueId: entity.remoteUniqueId } : { remoteUuid: entity.remoteUuid! },
            { includingMigrations: true, isRegularSyndication: true, separateUntil: 3 }
          )
          .then((response) => setRecent(response.items))
          .catch((e) => {});
      }}
    </SyncCoreApiContext.Consumer>
  );
}

export default WithMostRecentSyndications;
